@import '../ui/settings/settings.scss';

.events {
  &__header {
    display: inline-block;
    width: 100%;
    padding-bottom: $spacing-regular;
    &--title {
      display: inline-flex;
    }
    &--button {
      display: inline-flex;
      text-align: right;
      float: right;
    }
    &--back {
      display: flex;
      text-align: right;
      float: inherit;
      position: relative;
      padding-bottom: $spacing-small;
    }
  }
  &__form-group {
    padding: 0 0 $spacing-large 0;
    max-width: rem(700px);
    &--buttons {
      display: flex;
      vertical-align: middle;
      align-items: center;
      @include md {
        display: block;
      }
      & h3.h3--semi-strong {
        padding-bottom: 0px;
      }
      & button.btn.btn-primary {
        margin-right: $spacing-medium;
        @include md {
          width: 100%;
          display: block;
          margin-bottom: $spacing-small;
        }
      }
    }
    & .uploader__button {
      width: max-content;
      margin: 0;
      @include md {
        width: 100%;
        display: block;
      }
    }
    .form-field.full-width {
      margin-bottom: 0;
    }
  }
  &__row {
    background-color: $white;
    margin-bottom: $spacing-small;
    min-height: rem(160px);
    max-height: rem(160px);
    overflow: hidden;
    @include md {
      max-height: 100%;
    }
    & .photo-container__empty {
      margin: 0;
      min-height: rem(160px);
      width: 100%;
      @include md {
        width: 100%;
      }
    }
    & .photo-container__event-photo {
      margin: 0;
      min-height: rem(160px);
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }
    & .row .col-sm-7 {
      width: 70%;
      display: inline-block;
    }
    & .row .col-sm-2 {
      width: 30%;
      display: inline-block;
    }
  }
  &__event-details {
    display: block;
    position: relative;
    height: 100%;
    padding: $spacing-small;
    @include md {
      padding: $spacing-small;
      display: inline-block;
    }
    & h5.h5--semi-strong {
      position: absolute;
      bottom: $spacing-small;
      @include md {
        position: relative;
        bottom: 0;
        padding-top: $spacing-small;
      }
    }
  }
  &__actions {
    display: flex;
    right: 0;
    padding: $spacing-small;
    text-align: right;
    color: $blue-accent;
    @include md {
      display: inline-block;
    }
    & svg {
      cursor: pointer;
    }
  }
}

/* CUSTOM OVERRIDES */
.form-field.full-size {
  margin-bottom: 0;
}
.notification-preferences__switch-label {
  display: inline-flex;
}
.MuiFormGroup-root-88 {
  display: inline-block !important;
  flex-wrap: wrap;
  width: max-content;
  flex-direction: column;
}
.MuiFormGroup-root-240,
.MuiFormGroup-root-80 {
  display: inline-flex !important;
  flex-wrap: wrap;
  width: max-content;
  flex-direction: column;
}
.MuiFormControlLabel-root-90 {
  margin-right: 0 !important;
}
