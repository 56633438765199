@import '../ui/settings/settings.scss';

.create-school {
  &__tab-header {
    text-align: center;
    padding: $spacing-regular;
    @include md {
      padding: $spacing-smallest;
    }
  }
  &__tab-form {
    text-align: center;
    max-width: 60%;
    margin: auto;
    padding-bottom: $spacing-regular;
    @include md {
      max-width: 100%;
    }
    & h5 {
      text-align: left;
      padding-top: $spacing-smallest;
      & a {
        font-size: $size-h5;
      }
    }
  }
  &__cancel {
    text-align: center;
    padding: $spacing-regular 0 0 0;
  }
}
.MuiTab-labelContainer-25 {
  @include md {
    display: none;
  }
}
.container__create-school {
  max-width: rem(1000px);
  margin: auto;
  padding: $spacing-smallest $spacing-regular;
  background: $white;
  @include md {
    padding: $spacing-smallest $spacing-smaller;
  }
}
