.container__zoom {
  position: fixed;
  background-color: rgba(45, 45, 45, 0.92);
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 1000;
  flex: 2;
  display: flex;
  cursor: pointer;
}

.container__zoom__image {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 auto;
  text-align: center;
  overflow: visible;
  align-self: center;
  height: 100vh;
  align-items: center;
}

.container__zoom--icon {
  color: white;
  z-index: 1001;
  cursor: pointer;
  margin: 1em 0em;
}

.container__controls {
  margin: 4em 2em 0em 0em;
  width: 5%;
  z-index: 1;
  position: absolute;
  right: 0;
}

img.container__zoom--image-large {
  align-self: center;
  margin: 0 auto;
}

.react-transform-component {
  overflow: visible !important;
}
