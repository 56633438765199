@import '../settings/settings.scss';

// NAV BOOTSTRAP OVERRIDES ~~ DO NOT DRY UP

.hero__tabs {
  & a.nav-link {
    padding: $spacing-small $spacing-medium;
  }
  & .nav-tabs .nav-link.active, .nav-pills .nav-link.active {
    color: $blue-accent;
    border-bottom: rem(3px) solid $blue-accent;
    border-color: $white $white $blue-accent;
    padding: $spacing-small $spacing-medium;
  }
  & .nav-pills .nav-link.active {
    border-bottom: rem(0px);
    color: $white;
    background-color: #0076bb;
    &:hover {
      color: $white;
      background-color: #005691;
    }
  }
}

.nav-tabs .nav-link, .nav-pills .nav-link {
  color: $dark-grey;
  font-size: $size-h4;
  &:hover {
    border-color: transparent;
    color: $blue-accent;
    background-color: transparent;
  }
}

.nav-tabs, .nav-pills {
  border-bottom: none;
  padding: 0 $spacing-large;
  @include md {
    margin-top: $spacing-smaller;
    // padding: $spacing-medium 0 0 0;
  }
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent;
}

.nav-link {
  padding: $spacing-small $spacing-medium;
  margin-right: $spacing-small;
  @include md {
    padding: $spacing-none;
  }
}

.nav-tabs .nav-item {
  margin-bottom: $spacing-none;
  @include md {
    margin-bottom: $spacing-smaller;
    display: block;
    margin-right: 40%;
  }
}

.class-review {
  &__sub-menu {
    padding: rem(10px) 0px;
    border-bottom: 0px;
    margin-top: rem(10px);
  }
  &__tabs {
    border-top: 0px;
    float: none;
  }
  &__awards-sub-menu {
    display: flex;
    margin: rem(16px) 0px;
  }
}

.class-review__sub-menu {
  padding-top: $spacing-medium;
}

.class-review__sub-menu .nav-pills .nav-link{
  border: 1px solid #8e5ba6;
  border-radius: 0;
  padding: $spacing-small;
  color: #8e5ba6;
  margin-right: -1px;
  font-family: "Calibre", Helvetica, Verdana, sans-serif;
  font-size: rem(20px);
  &:hover{
    background-color: #e9deee;
    color: #8e5ba6;
    border: 1px solid #8e5ba6;
  }
}

.class-review__sub-menu .nav-pills .nav-link.active{
  padding: $spacing-small;
}

.class-review__sub-menu .nav-pills .nav-link.active{
  background-color: #e9deee;
  color: #8e5ba6;
  border: 1px solid #8e5ba6;
  &:hover{
    background-color: #e9deee;
    color: #8e5ba6;
    border: 1px solid #8e5ba6;
  }
}

.class-review__tabs > .nav > .nav-link.disabled {
    color: #b8abbe !important;
}