@import '../ui/settings/settings.scss';

.homepage {
  &__hero {
    background-color: $public-light-blue;
    height: 100%;
    display: flex;
    align-items: center;
    @include md {
      display: block;
    }
    &--cta {
      max-width: max-content;
    }
    &--image {
      float: left;
      display: inline-block;
      -webkit-clip-path: polygon(0 0, 80% 0, 100% 21%, 80% 100%, 26% 100%, 0 63%);
      clip-path: polygon(0 0, 80% 0, 100% 21%, 80% 100%, 26% 100%, 0 63%);
      @include md {
        width: 100%;
        display: block;
        float: none;
      }
    }
    &--copy {
      height: 100%;
      display: inline-block;
      margin-right: 0;
      padding: $spacing-large $spacing-regular;
      @include md {
        width: 100%;
        display: block;
        padding: $spacing-regular;
      }
      & h1.h1--strong {

        padding-bottom: $spacing-small;
        color: $public-blue;
        font-size: rem(50px);
        line-height: rem(50px);
        @include md {
          font-size: $spacing-regular;
          line-height: $spacing-regular;
          max-width: 100%;
        }
        @include sm {
          max-width: 100%;
        }
      }
      & p.body {
        font-size: rem(20px);
        line-height: rem(24px);
        max-width: rem(500px);
        @include md {
          max-width: 100%;
        }
        @include sm {
          max-width: 100%;
        }
      }
      & h4.semi-strong.public-link {
        color: $public-blue;
        cursor: pointer;
        font-size: rem(20px);
        line-height: rem(24px);
        text-decoration: underline;
      }
    }
  }
  &__about-section {
    background-color: #005691;
    height: 100%;
    display: flex;
    align-items: center;
    padding: $spacing-regular rem(70px) $spacing-large;
    -webkit-clip-path: polygon(100% 0, 100% 95%, 71% 100%, 0 95%, 0 0);
    clip-path: polygon(100% 0, 100% 95%, 71% 100%, 0 95%, 0 0);

    @include md {
      display: block;
      padding: 0 0 $spacing-large 0;
    }
  }
  &__explore-photographs {
    background-color: $public-green;
    height: 100%;
    display: flex;
    align-items: center;
    padding: $spacing-regular rem(70px) $spacing-large;
    -webkit-clip-path: polygon(100% 0, 100% 95%, 71% 100%, 0 95%, 0 0);
    clip-path: polygon(100% 0, 100% 95%, 71% 100%, 0 95%, 0 0);
    @include md {
      display: block;
      padding: 0 0 $spacing-large 0;
    }
    &--image {
      display: inline-block;
      padding-bottom: $spacing-regular;
      @include md {
        width: 100%;
      }
      & img {
        width: 100%;
      }
      & h6 {
        padding: 0 $spacing-regular;
        color: $white;
        font-size: rem(16px);
        line-height: rem(19px);
        @include md {
          text-align: left;
          // padding: $spacing-medium $spacing-regular;
        }
      }
    }
    &--image2 {
      width: 50%;
      display: inline-block;
      padding-bottom: $spacing-regular;

      @include md {
        width: 100%;
      }

      & img {
        padding: $spacing-large $spacing-large $spacing-small $spacing-large;
        width: 100%;

        @include md {
          padding: 0 $spacing-regular;
        }
      }

      & h6 {
        padding: 0 $spacing-large;
        color: $white;
        font-size: rem(16px);
        line-height: rem(19px);

        @include md {
          text-align: left;
          padding: $spacing-medium $spacing-regular;
        }
      }
    }
    &--cta {
      max-width: max-content;
    }
    &--copy {
      height: 100%;
      display: inline-block;
      margin-right: 0;
      color: $white;
      padding: $spacing-large 5%;
      @include md {
        width: 100%;
        display: block;
        padding: $spacing-large $spacing-regular;
      }
      & p.body {
        color: $white;
        font-size: rem(20px);
        line-height: rem(24px);
      }
      & h1.h1--strong.public {

        padding-bottom: $spacing-small;
        font-size: rem(50px);
        line-height: rem(50px);
        @include md {
          font-size: $spacing-regular;
          line-height: $spacing-regular;
          max-width: rem(350px);
        }
                @include sm {
                  font-size: $spacing-regular;
                  line-height: $spacing-regular;
                  max-width: 100%;
                }
      }
      & h4.h4--semi-strong.public-link {
        color: $white;
        font-size: rem(18px);
        line-height: rem(22px);
        cursor: pointer;
        text-decoration: underline;
      }
    }
    &--about-wa {
      height: 100%;
      display: inline-block;
      margin-right: 0;
      color: $white;
      @include md {
        width: 100%;
        display: block;
      }
      & p.body {
        color: $white;
        font-size: rem(20px);
        line-height: rem(24px);
      }
      & h1.h1--strong.public {

        padding-bottom: $spacing-small;
        font-size: rem(50px);
        line-height: rem(50px);
        @include md {
          font-size: $spacing-regular;
          line-height: $spacing-regular;
          max-width: rem(350px);
        }
                @include sm {
                  font-size: $spacing-regular;
                  line-height: $spacing-regular;
                  max-width: 100%;
                }
      }
      & h4.h4--semi-strong.public-link {
        color: $white;
        font-size: rem(18px);
        line-height: rem(22px);
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  &__featured-photos {
    padding: $spacing-largest rem(70px);
    @include md {
      padding: $spacing-regular;
    }
  }
  &__photo-quote {
    background-color: $white;
    height: 100%;
    display: flex;
    align-items: center;
    padding: $spacing-regular 0;
    @include md {
      display: flex;
      flex-wrap: wrap;
      &:nth-child(2) {
        flex-direction: column-reverse;
      }
    }
    &--image {
      width: 55%;
      float: left;
      display: inline-block;
      @include md {
        width: 100%;
        display: block;
      }
    }
    &--copy {
      width: 45%;
      height: 100%;
      display: inline-block;
      margin-right: 0;
      padding: $spacing-regular $spacing-regular $spacing-regular rem(150px);
      @include md {
        width: 100%;
        display: block;
        padding: $spacing-small 0;
      }
      & h2.h2--medium {
        font-size: rem(20px);
        line-height: rem(24px);
      }
      & h6.h5--semi-strong {
        font-size: rem(16px);
        line-height: rem(19px);
      }
      &:nth-child(1) {
        padding: $spacing-regular rem(150px) $spacing-regular $spacing-regular;
        @include md {
          padding: $spacing-regular 0;
        }
      }
    }
  }
  &__events {
    background-color: $public-yellow;
    height: 100%;
    display: flex;
    align-items: center;
    padding: $spacing-largest rem(70px);
    -webkit-clip-path: polygon(68% 0, 100% 7%, 100% 100%, 0 100%, 0 8%);
    clip-path: polygon(68% 0, 100% 7%, 100% 100%, 0 100%, 0 8%);
    @include md {
      display: block;
      -webkit-clip-path: none;
      padding: $spacing-regular;
      clip-path: none;
    }
    &--image {
      width: 50%;
      display: inline-block;
      @include md {
        width: 100%;
        display: block;
      }
      & img {
        width: 80%;
        margin: auto;
        @include md {
          width: 100%;
        }
      }
    }
    &--copy {
      width: 30%;
      height: 100%;
      display: inline-block;
      margin-right: 20%;
      color: $dark-grey;
      @include md {
        width: 100%;
        display: block;
      }
      & h2.h2--strong {
        max-width: rem(350px);
        color: $public-red;
      }
      & h4.h4--semi-strong {
        padding-bottom: $spacing-small;
      }
      & h4.h4--semi-strong.public-link {
        color: $public-red;
        cursor: pointer;
        padding-bottom: $spacing-small;
      }
    }
    &--next-event {
      padding-bottom: $spacing-small;
    }
  }
}
