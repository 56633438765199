.no-message {
  &-container {
    min-height: 150px;
    background: #fff;
    border: 2px solid rgba(0, 0, 0, 0.23);
  }
  &-sub-container {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    top: 56px;
  }
}