@import '../settings/settings.scss';

// HERO SECTION
.use-agreement { 
  &__hero {
    background-color: $background-grey;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: top;
    padding: rem(140px) rem(70px) $spacing-large;
    @include md {
      display: block;
      padding: $spacing-large 0;
    }
    &--copy {
      width: 50%;
      margin-left: 5%;
      display: inline-block;
      max-width: rem(600px);
      @include md {
        width: 100%;
        display: block;
        padding: $spacing-regular;
      }
      & strong {
        color: $public-blue;
      }
      & p {
        margin-bottom: rem(8px);
      }
    }
    &--header {
      width: 40%;
      height: 100%;
      display: inline-block;
      margin-right: 0;
      & h1.h1--strong {
        color: $public-blue;
        font-size: rem(70px);
        line-height: rem(60px);
      }
      @include md {
        padding-left: $spacing-regular;
      }
    }
  }
  &__agreement-buttons {
    display: block;
    height: auto;
    margin-bottom: 10px;
    & .button__title {
      width: auto;
    }
    & .outline-buttons__right {
      margin-left: 0;
      width: 50%;
      @include md {
        width: 100%;
        margin-bottom: $spacing-small;
      }
      &--selected {
        margin-left: 0;
        width: 50%;
        @include md {
          width: 100%;
          margin-bottom: $spacing-small;
        }
      }
    }
  }
  &__agreement-submit {
    display: block;
    height: auto;
    margin-top: rem(40px);
  }
}
.body {
  &__use-agreement {
    &--gallery {
      padding: $spacing-large;
      background-color: #ffffff;
    }
    &--form {
      padding: 0 $spacing-large $spacing-large;
      max-width: $container-width;
      margin: auto;
    }
  }
}

// MODEL RELEASES
.model-release {
  &__hero {
    background-color: $background-grey;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: top;
    padding: rem(80px) rem(70px) $spacing-large;
    @include md {
      display: block;
      padding: $spacing-large 0;
    }
    &--copy {
      margin-left: 5%;
      display: inline-block;
      @include md {
        width: 100%;
        display: block;
        padding: $spacing-regular;
      }
      & strong {
        color: $public-blue;
      }
    }
    &--header {
      width: 40%;
      height: 100%;
      display: inline-block;
      margin-right: 0;
      & h1.h1--strong {
        color: $public-blue;
        font-size: rem(70px);
        line-height: rem(60px);
      }
      @include md {
        padding-left: $spacing-regular;
      }
    }
  }
  &__model-release-buttons {
    display: block;
    height: auto;
    margin-bottom: $spacing-small;
    & .button__title {
      width: auto;
    }
    & .outline-buttons__right {
      margin-left: 0;

      height: auto;

      @include md {
        width: 100%;
      }
      &--selected {
        margin-left: 0;

        @include md {
          width: 100%;
        }
      }
    }
  }
  &__model-release-submit {
    display: block;
    height: auto; 
    margin-top: rem(40px);
  }
}
.body {
  &__model-release {
    &--gallery {
      padding: $spacing-large;
    }
    &--form {
      padding: $spacing-large $spacing-large;
      max-width: $container-width;
      margin: auto;
    }
  }
}
