@import '../settings/settings.scss';

// HERO SECTION
.container__hero {

  background-color: $white;
  color: $dark-grey;
  display: inline-block;
  width: 100%;
  border-bottom: $border-thin-grey;

  &--subHeaders {
    display: inline-block;
    width: 100%;
  }
  &--title-section {
    border-bottom: $border-thin-grey;
    display: flex;
    padding: $spacing-small 0 $spacing-small $spacing-largest;
    @include md {
      padding: $spacing-small 0 $spacing-small $spacing-largest;
    }

    @include sm {
      padding: $spacing-small 0 $spacing-small $spacing-large;
    }
    @include xs {
      padding: $spacing-small 0 $spacing-small rem(24px);
    }
    &---backbutton {
      margin-right: rem(27px);
      &----arrow {
        cursor: pointer;
      }
    }
  }
}
.hero {
  &__header {
    margin-bottom: $spacing-small;
    padding: 0 $spacing-large 0 0;
    color: $navigation-grey;
    @include md {
      display: inline-block;
      padding: 0 0 $spacing-smallest;
    }
    @include sm {
      margin-bottom: $spacing-smaller;
    }
  }
  &__sub-header,
  &__tabs {
    float: left;
    width: 100%;
    border-top: $border-thin-grey;
    @include md {
      & .nav-tabs {
        margin: 0 40% $spacing-medium 0;
      }
      & .nav-tabs .nav-link,
      & .nav-tabs .nav-link.active {
        padding: $spacing-smaller 0 0 0 !important; //Override necessary
        margin-bottom: 0;
      }
      & a.nav-link.active,
      & a.nav-link {
        font-size: $size-h4;
      }
      & .nav-tabs .nav-item {
        margin-bottom: 0;
      }
    }
  }
  &__timeline {
    float: right;
  }
  &__subheader {
    float: left;
    padding: 0 $spacing-large 0 0;
    margin-top: $spacing-smaller;
  }
  &__content {
    max-width: $container-width;
    margin: auto;
  }

  &-photo-count {
    color: #505050;
    margin-left: rem(10px);
    font-family: 'Calibre';
    font-weight: 600;
  }
}

.class-tracker__filters {
  display: flex;
  position: relative;
  top: 25px;
  @media (min-width: 1600px) {
    left: 10vw;
  }
  @media (min-width: 1500px) and (max-width: 1599px) {
    left: 10vw;
  }
  @media (min-width: 1400px) and (max-width: 1499px) {
    left: 10vw;
  }
  @media (min-width: 1300px) and (max-width: 1399px) {
    left: 10vw;
  }

  h4 {
    position: absolute;
    top: 18px;
  }

  ul {
    display: flex;
    margin-left: 20px;
    li {
      width: rem(140px);
      height: rem(32px);
      list-style-type: none;
      padding-left: 20px;
      margin-top: 19px;
      border-bottom: solid;
      border-color: #d5d5d7;
      font-family: Calibre;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: 0.2px;
      color: #2d2d2d;
    }

    li.selected {
      border-color: $wa-mui-blue;
      svg {
        color: $wa-mui-blue;
      }
    }

    li.smallerWidth {
      width: rem(100px);
    }

    svg {
      vertical-align: middle;
      margin-right: rem(10px);
    }
  }
}

.collapse-all {
  position: absolute;
  top: 110px;
  left:86%;
  width: 102px;
  height: 18px;
  font-family: Calibre;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.1px;
  color: $wa-mui-blue;

  svg {
    vertical-align: middle;
  }
}

.hero-photo-count {
  color: #505050;
  margin-left: rem(10px);
  font-family: 'Calibre';
  font-weight: 600;
}

.margin-top-12 {
  margin-top: -12px;
}
