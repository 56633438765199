@import '../ui/settings/settings.scss';

.dashboard {
  &__container {
    margin-top: -4px;
  }
  &__intro {
    max-width: 66.666%;
    display: inline-block;
    padding-right: $spacing-large;
    @include md {
      max-width: 100%;
      padding-right: 0;
    }
  }
  &__project-timeline {
    display: block;
    width: 100%;
    padding: rem(60px) 0;
  }
  &__bios {
    display: block;
    width: 100%;
    padding: rem(60px) 0;
  }
  &__bio-approvals {
    &--container {
      width: 32%;
      margin: 2% 1% 2% 0;
      display: inline-block;
      @include md {
        width: 50%;
        margin: 4% 0;
      }
      @include sm {
        width: 100%;
        margin: 4% 0;
      }
    }
    &--name {
      padding: 0 0 $spacing-smallest 0;
    }
  }
  &__featured-resources {
    &--resource {
      background: $white;
      width: 32%;
      display: inline-flex;
      height: auto;
      margin-right: 1%;
      flex-wrap: wrap;
      padding: $spacing-medium;
      & a {
        display: block;
        width: max-content;
      }
      & p {
        display: block;
        width: 100%;
      }
      @include md {
        width: 49%;
        margin-right: 1%;
      }
      @include sm {
        width: 100%;
      }
    }
    & h2.h2--semi-strong {
      padding-bottom: $spacing-small;
    }
    &--subheader {
      padding: $spacing-smaller 0 $spacing-small;
      max-width: rem(600px);
    }
    &--title {
      padding-top: $spacing-large;
      border-top: $border-thin-grey;
    }
  }
  &__review-header {
    padding: rem(60px) 0 0 0;
  }
  &__cohort-name {
    overflow-wrap: break-word;
  }
  &__table-lists {
    height: 182px;
    min-height: 180px;
    overflow: auto;
  }
  &__no-result-container {
    width: 100%;
  }
  &__no-result-text {
    background: #f7f7f7;
    padding: 10px;
    border-radius: 5px;
    font-size: 15px;
    text-align: center;
  }
  &__pagination {
    text-align: center !important;
    font-size: 15px;
  }
  &__info-list-tables {
    border: 1px solid #d5d5d7;
    background: #ffffff;
    min-height: 250px;
    margin-top: $spacing-smaller;
  }
}

// CURATOR DASH
.cards {
  &__top-container {
    padding: 0 0 $spacing-regular 0;
    & h4.h4--semi-strong.link {
      padding-top: 0;
      @include md {
        width: 100%;
        text-align: left;
      }
    }
  }
  &__middle-container {
    padding: 0 0 $spacing-medium;
    margin-top: -$spacing-small;
    & h4.h4--semi-strong.link {
      text-align: left;
      padding-top: $spacing-smaller;
      @include md {
        text-align: left;
      }
    }
  }
  &__photo-container {
    position: relative;
    &--date {
      padding: $spacing-small;
      text-align: left;
      background-color: #fff;
      & h3.h3--semi-strong {
        padding-bottom: 0;
      }
      & h1.h1--thin {
        padding-bottom: 0;
      }
      @include md {
        text-align: left;
      }
    }
  }
  &__bottom-container {
    & h4.h4--semi-strong.link {
      text-align: left;
      padding-top: 0;
      @include md {
        text-align: left;
      }
    }
  }
  &__pagination {
    padding: $spacing-small 0;
  }
}
.card-img-top {
  width: 100%;
  border-top-left-radius: rem(0px);
  border-top-right-radius: rem(0px);
}

/* FACILITATOR PHOTO EMPTY CONTAINER HEIGHT FIX */
.photo-container {
  &__empty {
    &--facilitator-dashboard {
      height: 15vw;
    }
  }
}

.dashboard__cards {
  & .card {
    border: none;
    margin: 1.25rem;
    min-width: 300px;
  }

  & .card-columns {
    display: inline-block;
  }
}

// Curator + Admin

.h4--semi-strong.dashboard__cohort-name {
  @media (min-width: 999px) and (max-width: 1800px) {
    width: 80%;
    text-align: left;
  }
}
