@import '../ui/settings/settings.scss';

.error-page {
  &__section {
    padding-top: 15vH;
  }
  &__sub-section {
    margin-top: $spacing-small;
  }
  &__public-section {
    padding: 12rem;
  }
  &__h2-heading {
    color: $blue-accent;
  }
  &__h3-heading {
    color: #005691
  }
}