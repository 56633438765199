@import '../ui/settings/settings.scss';

.body__model-release {
  margin: auto;
  padding: $spacing-large $spacing-regular;
  background-color: $white;
  width: 100%;
  &--intro {
    margin: auto;
    padding: $spacing-large $spacing-regular;
    width: 100%;
  }

  .MuiFormLabel-root.Mui-focused {
    color: $wa-mui-blue !important;
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid $wa-mui-blue !important;
  }

}
.model-release {
  &__section {
    max-width: rem(1000px);
    margin: auto;
  }
  &__agreement-buttons {
    display: inline-block;
    padding: 0 0 $spacing-medium 0;
    width: 100%;
  }

}
.model-release__gallery {
  & img.gallery__photo {
    width: 100%;
  }
}
.model-release__agreement-buttons {
  & .button__title {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: 100%;
    margin: auto;
  }
}

.model-release__link {
  font-weight: bold;
  color: #0076bb;
  cursor: pointer !important;
  &:hover {
    color: #0056b3;
  }
}

.model-release__link-font-family {
  font-family: "Calibre Semibold", Helvetica, Verdana, sans-serif;
  font-size: rem(18px);
}

.model-release__link-font-family {
  font-family: "Calibre Semibold", Helvetica, Verdana, sans-serif;
  font-size: rem(18px);
}

.MuiPaper-root ul {
  // height: 155px;
  // overflow: scroll;
}

.model-release {
  &__selection-buttons {
    padding-top: rem(32px);
    padding-bottom: rem(32px);
    display: flex;
  }
  &__buttons{
    width: 100%;
  }
  &__buttons-section {
    margin-right: rem(15px);
    & .outline-buttons__right, .outline-buttons__left,
      .outline-buttons__right--selected, .outline-buttons__left--selected {
      margin-top: 0;
      padding: rem(24px) rem(48px);
    }
  }
  &__section {
    &--heading {
      margin-bottom: rem(24px);
    }
    &--questions {
      padding-bottom: rem(32px);
    }
    &--questions h3 {
    }
  }
  &__signee-details-section {
    padding-top: rem(48px);
  }
  &__hero {
    &--copy  {
      &-line-br::after {
        content: "\a\a";
        white-space: pre;
      }
    }
  }
}

.mr-note__section > p.body {
  margin-bottom: 8px;
}

.send-pdf__description {
  padding: 1rem 0 0.2rem 0;
}

.send-pdf__links--downloads {
  padding-bottom: rem(6px);
}

.send-pdf__submit {
  margin-top: 1.75rem;
}

.send-pdf__links {
  margin-top: -1.5rem;
  padding-bottom: 0;
}