@import '../settings/settings.scss';

// PHOTO EDIT FIELD AREAS
.featured-photo__meta {
  margin-top: $spacing-small;
  color: $grey;
}
.body {
  &__featured-photo {
    text-align: center;
    &--replace-button {
      width: max-content;
      margin: $spacing-small auto;
      @include md {
        margin: $spacing-medium 0;
        width: 100%;
      }
    }
  }
  &__editor {
    text-align: center;
    padding: $spacing-regular;
    &--active {
      text-align: center;
      padding: rem($spacing-smaller);
    }
    &--name {
      margin: $spacing-smaller auto $spacing-smaller;
      text-align: center;
    }
    &--title,
    &--title2 {
      margin: $spacing-smaller auto 0;
      text-align: center;
      max-width: rem(768px);

      .h1--editable {
        margin: 0 auto;
        padding: 0;
      }
      .h1--editing {
        padding: 0;
      }
      & input[type='text'] {
        display: inline;
        font-size: $spacing-regular;
        font-family: $sans-serif-xbold;
        text-align: center;
        &::placeholder {
          opacity: 0.3;
        }
      }
    }
    &--caption,
    &--caption2 {
      text-align: left;
      padding-bottom: $spacing-small;
      margin: 0 auto;
      max-width: rem(768px);

      & textarea {
        width: 100%;
        font-size: rem(20px);
        text-align: left;
        line-height: $line-height-base;
        overflow-wrap: normal;
        padding-bottom: $spacing-medium;
        &::placeholder {
          opacity: 0.3;
        }
      }
    }
    &--placeholder {
      opacity: 0.3;
    }
  }
}

.photo-editor-page.outline-buttons {
  margin-top: 2rem;
  width: 151px;
  height: 50px;
}
.photo-editor-alt-page.outline-buttons{
  margin-top: 8px;
  width: 151px;
  height: 50px;
}

.photo-editor-page {
  &__button-section {
    display: flex;
    & h4.h4--semi-strong.link {
      display: inline;
      float: none;
      width: fit-content;
      margin-left: 1rem;
      padding-top: 3rem;
    }
  }
}

.body__editor--title,
.body__editor--caption {
  input[name='title'],
  textarea[name='caption'],
  textarea[name='altText'], textarea[name='mrAdminNotes'] {
    // need all these importants to override material UI stuff...
    &:hover {
      border-style: solid !important;
      border-color: $black !important;
      border-width: rem(2px) !important;
    }

    &:focus {
      border-style: solid !important;
      border-width: rem(2px) !important;
      border-color: $wa-mui-blue !important;
      opacity: 1 !important;
    }

    &.greyed-out {
      opacity: 0.5;
    }

    &.blackened-out {
      opacity: 1;
    }
  }
}

.body__editor--title,
.body__editor--caption,
.body__editor--title2,
.body__editor--caption2 {
  textarea[name='altText'], textarea[name='mrAdminNotes'] {
    padding: 10px 14px;
    overflow: hidden;
    resize: vertical;
    word-wrap: break-word;
    resize: none;
  }

  textarea[name='caption'] {
    overflow: none;
    padding: 10px 14px;
    resize: vertical;
    word-wrap: break-word;
    resize: none;
  }
}

.body__editor--caption {
  textarea[name='altText'], textarea[name='mrAdminNotes'] {
    border-style: solid;
    border-width: rem(1px);
    border-color: $light-grey;
    height: 60px !important;
    resize: none;
  }
}
.body__editor--alt-text {
  padding-bottom: 0;
}
.photo-alttext {
  &__section {
    padding: $spacing-large 0 48px;
    border-style: solid;
    border-width: rem(1px) 0 0 0 ;
    border-color: $light-grey;
  }
  &__instructions {
    margin: $spacing-smallest 0 ;
  }
}
