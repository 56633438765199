@import '~sass-rem';

// ================= TYPOGRAPHY
$sans-serif-light:     'Calibre Light', Helvetica, Verdana, sans-serif;
$sans-serif:           'Calibre', Helvetica, Verdana, sans-serif;
$sans-serif-medium:    'Calibre Medium';
$sans-serif-bold:      'Calibre Semibold', Helvetica, Verdana, sans-serif;
$sans-serif-xbold:     'Calibre Bold', Helvetica, Verdana, sans-serif;
$serif:                'Freight', Georgia, "Times New Roman", serif;
$lyon:                 'Lyon';

$size-h1:               rem(50px) !default;
$size-h2:               rem(24px) !default;
$size-h3:               rem(20px) !default;
$size-h4:               rem(16px) !default;
$size-h5:               rem(14px) !default;
$size-h6:               rem(12px) !default;
$size-body:             rem(18px) !default;
$size-blockquote:       rem(18px) !default;
$size-extra:            rem(60px) !default;
$size-over:             rem(40px) !default;

$line-height-none:      1;
$line-height-base:      1.6;
$line-height-short:     1.2;

$letter-spacing:        0.2px;

$weight-regular:        $sans-serif;
$weight-bold:           $sans-serif-bold;
$weight-xbold:          $sans-serif-xbold;

// ================= COLORS
$black:                 rgba(0, 0, 0, 1);
$darker-grey:           rgba(25, 25, 28, 1);
$navigation-grey:       rgba(45, 45, 45, 1);
$dark-grey:             rgba(51, 51, 60, 1);
$grey:                  rgba(118, 118, 122, 1);
$gery53:                rgba(135, 135, 135, 1);
$light-grey:            rgba(213, 213, 215, 1);
$white:                 rgba(255, 255, 255, 1);
$background-grey:       rgba(247, 247, 247, 1);
$blue-accent:           rgba(0, 118, 187, 1);
$blue-hover:            rgba(0, 86, 145, 1);
$light-blue-accent:     rgba(40, 152, 212, 0.5);
$purple-accent:         rgba(142, 91, 166, 1);
$red-accent:            rgba(222, 34, 18, 1);
$red-status:            rgba(241, 91, 78, 1);
$red-error:             #DE2212;
$green-status:          rgba(0, 129, 96, 1);
$green-accent:          rgba(0, 129, 96, 1);
$yellow-accent:         rgba(255, 204, 50, 1);
$dark-overlay:          rgba(0, 0, 0, 0.5);
$transparent-grey:      rgba(26, 26, 21, 0.5);
$transparent-green:     rgba(20, 204, 150, 0.1);
$transparent-blue:      rgba(40, 152, 212, 0.2);
$transparent-red:       rgba(255, 75, 87, 0.1);
$transparent-purple:    rgba(142, 91, 166, 0.1);
$grey-hover:            #E7E7E7;
$grey-border:           #505050;
$silver:                rgb(200, 200, 200);
$silver-opacity:        rgba(200, 200, 200, 0.1);


$public-blue:           rgba(0, 86, 145, 1);
$public-light-blue:     rgba(207, 237, 252, 1);
$public-green:          rgba(0, 116, 107, 1);
$public-tan:            rgba(255, 232, 212, 1);
$public-red:            rgba(241, 91, 78, 1);
$public-yellow:         rgba(255, 249, 175, 1);
$selected-option:       rgba(230, 242, 240, 1);

// ================= RESPONSIVENESS
$container-width:       rem(1140px);
$full-height:           100vh;
$nav-menu-width:        rem(200px);
$xsmallphone:           rem(320px);
$smallphone:            rem(375px);
$phone:                 rem(636px);
$tablet:                rem(936px);
$desktop:               rem(991px);
$largedesktop:          rem(1200px);
$fullhd:                rem(1430px);


// ================ MARGINS & PADDING
$spacing-none:           rem(0px);
$spacing-smallest:       rem(4px);
$spacing-smaller:        rem(8px);
$spacing-small:          rem(16px);
$spacing-18px:           rem(18px);
$spacing-24px:           rem(24px);
$spacing-medium:         rem(32px);
$spacing-regular:        rem(32px);
$spacing-large:          rem(48px);
$spacing-largest:        rem(64px);
$spacing-wide:           rem(0.5px);


// ================ MISC
$radius-none:           rem(0px) !default;
$radius-rounded:        rem(50px) !default;
$transition-fast:       .2s ease-in-out;
$transition-medium:     .5s ease-in-out;
$transition-slow:       1.0s ease-in-out;
$border-thin-grey:      rem(1px) solid $light-grey;
$border-thin-transparent:      rem(1px) solid transparent;
$border-dotted:         rem(4px) dotted $blue-accent;
$border-dotted-red:     rem(4px) dotted $red-accent;
$border-purple:         rem(2px) solid $purple-accent;


// Material UI colors
$wa-mui-blue: rgba(40, 152, 212, 1);

:export {
    sizeH1: $size-h1;
    sizeH2: $size-h2;
    sizeH3: $size-h3;
    sizeH4: $size-h4;
    sizeH5: $size-h5;
    sizeH6: $size-h6;
    sizeBody: $size-body;
    sizeExtra: $size-extra;
    sizeOver: $size-over;
    sansSerifBold: $sans-serif-bold;
    sansSerifLight:$sans-serif-light;
    sansSerif:$sans-serif;
    sansSerifMedium: $sans-serif-medium;
    sansSerifXbold: $sans-serif-xbold;
    serif: $serif;
    lyon:$lyon;
    letterSpacing:$letter-spacing;
    lineHeightShort: $line-height-short;
    darkGrey: $dark-grey;
    lightGrey: $light-grey;
    spacingSmall: $spacing-small;
    spacingMedium: $spacing-medium;
    spacingRegular: $spacing-regular;
    spacingLargest:$spacing-largest;
    spacingLarge: $spacing-large;
    publicBlue: $public-blue;
    white: $white;
    black: $black;
    blueAccent: $blue-accent;
    blueHover: $blue-hover;
    purpleAccent: $purple-accent;
    publicLightBlue: $public-light-blue;
    publicGreen: $public-green;
    gery53:$gery53;
    navigationGrey: $navigation-grey;
    spacing18px: $spacing-18px;
    borderThinGrey: $border-thin-grey;
    backgroundGrey: $background-grey;
    greenAccent: $green-accent;
    redAccent: $red-accent;
    selectedOption: $selected-option;
    redError: $red-error;
    greyHover: $grey-hover;
    greyBorder: $grey-border;
    spacing24px: $spacing-24px;
    greenStatus: $green-status;
    yellowSccent: $yellow-accent;
    redStatus: $red-status;
    darkerGrey: $darker-grey;
    silver: $silver;
    silverOpacity: $silver-opacity
}