@import '~sass-rem';
@import '../settings/settings.scss';

.upcoming-reviews {
  &__flex-section {
    display: flex;
    &--boxes {
      // width: 30%;
      padding: rem(32px);
      background: #ffffff;
      margin-right: rem(16px);
      &---subsection {
        margin-top: rem(16px);
      }
    }
  }
}

.margin_bottom_27{
  margin-bottom:-5px;
}