@import '../ui/settings/settings.scss';

.sign-up {
  &__hero {
    height: 100%;
    display: inline-flex;
    width: 50%;
    @include lg {
      display: inline-block;
      min-width: 57%;
    }
    @include md {
      display: none;
      min-width: 0;
    }
  }
  &__content {
    width: 50%;
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    padding: rem(75px);
    @include md {
      width: 100%;
      display: block;
      padding: $spacing-large $spacing-regular;
    }
    &--header {
      & .wrkxfmly_logo {
        // width: rem(400px);
        // max-width: rem(400px);
        // padding: 0 0 $spacing-small 0;
        // @include sm {
        //   width: rem(386px);
        //   height: auto;
        // }
        // @include md {
        //   width: rem(375px);
        //   height: auto;
        // }
        // & svg {
        //   height: 56px;
        // }
      }
    }
    &--copy {
      // margin: rem(40px) auto;
      & .btn-primary {
        background-color: $purple-accent;
        border-color: $purple-accent;
      }
    }
  }
}
.public-body {
  &__sign-up {
    display: flex;
    margin-top: rem(60px);
    align-items: top;
    @include md {
      margin-top: 0;
    }
  }
  &__sign-up-flow {
    background-color: #ffe8d4;
    // padding: rem(160px) 0;
    // @include md {
    //   padding: rem(160px) 0;
    // }
  }
}
.sign-up-flow {
  &__container {
    background: $white;
    // max-width: rem(900px);
    // margin: auto;
    // padding: $spacing-small $spacing-regular;
  }
  &__tab-form {
    text-align: center;
    max-width: rem(500px);
    padding: $spacing-medium $spacing-small;
    margin: auto;
    &--split {
      display: inline-block;
      width: 50%;
    }
  }
  &__tab-form.final {
    @include md {
      form {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
  &__tab-content {
    text-align: center;
    // max-width: rem(400px);
    // padding: $spacing-small 0 0 0;
    // margin: auto;
    &--declined {
      text-align: center;
      // padding: $spacing-large $spacing-regular;
      max-width: rem(500px);
      margin: auto;
    }
  }
}
h4.h4--semi-strong.link {
  text-align: center;
  width: 100%;
  padding-top: $spacing-medium;
}
button.btn.btn-primary {
  @include md {
    width: 100%;
  }
}

.error-page {
  &__sub-section {
    p.body {
      margin-top: -1px;
    }
  }
  &__h2-heading {
    color: #005691;
  }
}

.entity {
  &__name {
    font-weight: bolder;
  }
  &__email {
    font-weight: bolder;
    color: #0076bb;
  }
}
.ml-25p {
  margin-left: 25%;
}

.create_acc_btn {
  @include sm {
    width: 100%;
  }
}