@import '../settings/settings.scss';

.body__photo-release {
  padding: 2.8rem 0 0.75rem;
  border-top: $border-thin-grey;
  margin-bottom: 1.26rem;
  @include md {
    padding: $spacing-small $spacing-smaller;
  }
}

#mb_26{
  margin-bottom:26px;
}

.photo-release {


  &__statuses {
    display: flex;
    position: relative;
    align-items: center;
  }
  &__chevron {
    cursor: pointer;
  }
  &__upload-release {
    padding: $spacing-small 0 0 0;
    & .container__uploader .uploader__button {
      margin-left: 0px;
    }
  }
  &__release-summary-data {
    padding-top: $spacing-small;
  }
  &__status {
    &--success {
      color: $green-accent;
      margin-top: $spacing-small;
    }
    &--alert {
      color: $red-accent;
      margin-top: $spacing-small;
    }
  }
  &__publishing-buttons {
    display: inline-block;
    width: 100%;
    margin-bottom: $spacing-smallest;
    & p.p--strong,
    p {
      color: $light-grey;
    }
  }
  &__title {
    display: inline-flex;
    align-items: center;
    padding: 0 $spacing-small;
    @include md {
      width: max-content;
      display: flex;
      vertical-align: middle;
      align-items: center;
      & h3.h3--strong {
        padding-bottom: 0;
      }
    }
  }
  &__accordion {
    padding : 0 $spacing-small;
  }
  &__step-number {
    width: $spacing-medium;
    border: rem(2.5px) solid $black;
    border-radius: $spacing-regular;
    text-align: center;
    display: inline-block;
    & h3.h3--strong {
      padding: 0;
      line-height: rem(25px);
    }
    &--success {
      width: $spacing-medium;
      display: inline-block;
      @include md {
        width: max-content;
        padding-left: $spacing-smaller;
        & h3.h3--strong {
          padding-bottom: 0;
        }
      }
    }
    &--warning {
      width: $spacing-medium;
      display: inline-block;
      @include md {
        width: max-content;
        padding-left: $spacing-smaller;
        & h3.h3--strong {
          padding-bottom: 0;
        }
      }
    }
    &--alert {
      width: $spacing-medium;
      display: inline-block;
      @include md {
        width: max-content;
        padding-left: $spacing-smaller;
        & h3.h3--strong {
          padding-bottom: 0;
        }
      }
    }
  }
  &__status {
    padding-left: rem(50px);
    margin-top: -$spacing-smaller;
    @include md {
      padding-left: 0;
      margin-top: $spacing-smaller;
    }
  }
  &__alert {
    &--success {
      color: $green-accent;
      @include md {
        padding-left: $spacing-smaller;
        margin-top: $spacing-smallest;
      }
    }
    &--warning {
      color: $red-accent;
      @include md {
        padding-left: $spacing-smaller;
        margin-top: $spacing-smallest;
      }
    }
  }
  &__description {
    padding: 0 $spacing-regular 0;
    display: inline-block;
    width: 100%;
    @include md {
      padding: 0 $spacing-smaller;
    }
  }
  &__buttons {
    & button.btn.btn-primary {
      margin-right: $spacing-smaller;
      & h3.h3--semi-strong {
        padding-bottom: 0;
      }
      @include md {
        margin-bottom: $spacing-small;
        padding-left: $spacing-smaller;
      }
    }
    &__buttons {
      & button.btn.btn-primary {
        margin-right: $spacing-smaller;
        & h3.h3--semi-strong {
          padding-bottom: 0;
        }
        @include md {
          margin-right: 0;
          width: 100%;
          margin-bottom: $spacing-small;
        }
      }
      & button.btn.btn-outline-dark {
        & h3.h3--semi-strong {
          padding-bottom: 0;
        }
      }
    }
  }
  &__notes {
    padding: $spacing-small 0 0 $spacing-regular;
    & h4.h4--semi-strong {
      line-height: rem(16px);
    }
    &--confirmations {
      padding-top: $spacing-smaller;
    }
    &--confirmation-box{
      margin-bottom:rem(16px);
    }
    &--release-confirmed {
      padding-top: rem(9px);
      color: #008160;
    }
    &--release-declined {
      padding-top: rem(9px);
      color: $red-accent;
    }
  }
  &__instruction-section {
    margin: 0 0;
  }
  &__hasNoPeople--checkbox {
    margin-top: -5px;
    margin-bottom: 23px;
  }
}

.modelReleaseDates {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.09px;
  color: #505050;
}

.model-release {
  &__labels {
    border-top: 1px solid #d5d5d7;
    padding: rem(8px) rem(15px);
  }

  &__list {
    border: 1px solid $light-grey;
    padding: rem(24px) rem(24px) 1.9rem rem(24px);
    margin-top: $spacing-small;
    &-heading-section {
      display: flex;
      margin-top: -3px;
    }
    &-section {
      margin-top: $spacing-medium;
    }
    &--links {
      cursor: pointer;
    }
    &-delete-link {
      position: absolute;
      right: 0;
    }
    &-mr {
      &-note {
        margin: 3px 0 0 0;
        margin-bottom:-8px;
      }
      &-section{
        margin-top: 0.3rem;
      }
    }
    &-mr-add-link {
      color: #0076bb;
      padding: 0;

    }
    &-history{
      &-divider {
        margin: $spacing-regular 0;
      }
      &-mr {
        &-setion {
          display: flex;
        }
        &-status {
          margin-bottom: rem(12px);
          margin-top: rem(-4px);
          &--mb_12{
            margin-bottom:12px;
          }
          &--inactive {
            // background-color: #ffffff;
            z-index: 2;
            opacity: 0.5;
          }
        }
        &-dropdown {
          width: 20%;
        }
      }
    }
    &--success {
      color: $green-accent;
    }
    &--alert {
      color: $red-accent;
    }
    &--blocked {
      color: #878787;
    }
    &--admin-decision-status {
      margin-top: $spacing-smaller;
    }
    &-accordion {
      padding-top: $spacing-smaller;
      cursor: pointer;
      margin-bottom:-15px;

    }
  }
  &__description-text {
    margin-top: rem(32px) ;
  }
  &__dropdown--icon {
    position: relative;
    top: -16px;
  }
}

.body__photo-release > .alert {
  margin-bottom: 10px;
}