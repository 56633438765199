@import '../ui/settings/settings.scss';

.body {
  &__resources {
    padding: $spacing-large $spacing-large $spacing-large;
    @include md {
      padding: $spacing-regular $spacing-small;
    }
  }
  &__resource-list {
    margin-top: -20px;
    padding: $spacing-large;
    background: $white;
  }
}
.resources {
  &__header,
  &__get-support {
    max-width: 60%;
    margin-left: 0;
    @include md {
      max-width: 100%;
    }
  }
  &__all-resources {
    text-align: center;
  }
  &__link-blocks {
    margin-top: $spacing-medium;
  }
  &__new-resource {
    padding-bottom: $spacing-large;
    @include md {
      padding-bottom: $spacing-smaller;
    }
  }
  &__header {
    text-align: center;
    margin-bottom: $spacing-medium;
  }
}
.resource-category {
  display: flex;
  flex: 2;
  margin-bottom: $spacing-regular;
  &__hero {
    text-align: center;
    background-color: $background-grey;
    &--icon {
      padding-bottom: $spacing-small;
    }
  }
  &__header {
    text-align: left;
    margin-bottom: $spacing-small;
    display: block;
    width: 100%;
  }
  &__link-group {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    display: flex;
    flex-direction: column;
  }
  &__link {
    display: inline-flex;
    padding-bottom: $spacing-smaller;
    align-items: center;
    @include md {
      width: 100%;
    }
    &--icon {
      vertical-align: middle;
      display: flex;
      padding-left: $spacing-smaller;
      color: $blue-accent;
      cursor: pointer;
    }
  }
}
.resource-video-link svg {
  margin-top:rem(3px)
}
.new-resource {
  &__checkboxes--row {
    .FormCheckbox-root-87,
    .FormCheckbox-root-90 {
      padding-right: 5px;
    }
  }
  &__form {
    &--resource-name,
    &--category,
    &--users {
      padding: 0 0 $spacing-medium 0;
    }
    &--form-field {
      max-width: rem(350px);
    }
  }
  &__copy-link--buttons {
    & .uploader__button {
      margin: 0;
    }
  }
}
.container {
  &__link-block {
    width: 30%;
    margin-right: 3%;
    background-color: $white;
    display: inline-block;
    padding: $spacing-medium;
    &:last-child {
      margin-right: $spacing-none;
    }
  }
  &__resource-hero {
    background-color: $background-grey;
  }
  &__resource-block {
    width: 33.333%;
    background-color: $white;
    display: inline-block;
    text-align: center;
    padding: $spacing-regular $spacing-small $spacing-regular 0;
    &:last-child {
      margin-right: $spacing-none;
    }
    & h3.h3--semi-strong {
      padding-top: $spacing-small;
    }
  }
}

.resource-category__container {
  height: 100%;
  background: white;
}
.new-resource__form--users {
  p {
    padding-bottom: 10px;
  }
}
