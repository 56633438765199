@import '../ui/settings/settings.scss';

// ACCOUNT DETAILS
.my-account {
  &__about-you--credit-buttons {
    margin-bottom: $spacing-regular;
    display: inline-block;
    width: 100%;
  }
  &__intro {
    display: inline-block;
    width: 100%;
  }
  &__approve-bio {
    padding: $spacing-small 0 $spacing-medium;
  }
  &__required-info,
  &__about-you,
  &__school-info {
    width: 100%;
    border-bottom: $border-thin-grey;
    display: inline-block;
    padding-bottom: $spacing-large;
    margin-bottom: $spacing-large;
    padding-right: 30%;
    @include xl {
      padding-right: 20%;
    }
    @include md {
      padding-right: 0;
    }
  }
  &__school-information {
    width: 100%;
    padding-right: 0%;
    display: inline-block;
  }
  &__password-security {
    width: 100%;
    padding-right: 30%;
    margin-bottom: $spacing-large;
    @include md {
      padding-right: 0;
    }
    &--reset-password {
      width: 100%;
      margin-bottom: $spacing-large;
      padding-bottom: $spacing-large;
      border-bottom: $border-thin-grey;
    }
  }
  &__admin-security {
    // padding-top: $spacing-large;
    // border-top: $border-thin-grey;
    &--reset-password {
      margin: $spacing-small 0 $spacing-regular;
    }
  }
}

// DATEPICKER OVERRIDES
.react-datepicker-wrapper {
  min-width: -webkit-fill-available;
}
.react-datepicker__input-container {
  min-width: -webkit-fill-available;
}
input[type='text'] {
  width: 100%;
}

// NOTIFICATION PREFERENCES
.notification-preferences__intro {
  max-width: 100%;
  padding-right: 30%;
  display: inline-block;
  margin: $spacing-medium 0;
}
.notification-preferences__row {
  margin-bottom: $spacing-regular;
}
.notification-preferences__labels {
  padding-bottom: $spacing-smaller;
  color: $grey;
}
