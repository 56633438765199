@import '../ui/settings/settings.scss';

.body {
  &__project-detail {
    max-width: rem(100%);
    overflow: hidden;
    padding: 3rem 3rem 2rem;
  }
  &__submit-project {
    padding: $spacing-large;
    background-color: $white;
    display: grid;
    margin: auto;
    width: rem(60%);
  }
}
.my-projects {
  &__row {
    background-color: $white;
    overflow: hidden;
    margin-top: $spacing-small;
    &--section {
      padding: $spacing-small;
      height: rem(100%);
    }
    & .row .col-sm-5 {
      padding-bottom: 0;
    }
  }
  &__zero-state {
    max-width: rem(600px);
  }
  &__list-titles {
    @include md {
      display: none;
    }
    & .row .col-sm-5 {
      padding-bottom: 0;
      padding-left: $spacing-small;
      padding-top: $spacing-small;
    }
    & .row .col-sm-4 {
      padding-bottom: 0;
      padding-left: $spacing-small;
      padding-top: $spacing-small;
    }
  }
  &__featured-photo {
    // HACK TO CLICK TO PROJECT
    & a {
      width: 100%;
      height: auto;
      display: block;
      z-index: 999;
      padding-bottom: 0;
    }
    & .container__photo {
      pointer-events: none;
    }
  }
  &__project-details {
    &--main-details {
      position: relative;
      display: block;
      height: 100%;
      @include lg {
        height: 80%;
      }
      @include md {
        height: 60%;
      }
    }
    &--teacher-facilitator {
      position: relative;
      bottom: $spacing-small;
      & h6.h6--semi-strong {
        display: inline;
        padding-right: $spacing-small;
        @include lg {
          display: block;
        }
      }
      @include md {
        position: initial;
      }
    }
  }
}
h3.project-detail {
  padding-bottom: $spacing-small;
}
.container__photo--background {
  height: rem(220px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @include xxl {
    height: rem(400px);
  }
  @include xl {
    height: rem(200px);
  }
  @include lg {
    height: rem(160px);
  }
  @include md {
    height: rem(240px);
  }
}
.photo-container__empty {
  background-color: $purple-accent;
  width: auto;
  margin: 0;
  height: 100%;
  padding: rem(60px) $spacing-regular;
  display: block;
  align-items: center;
  text-align: center;
  @include lg {
    padding: $spacing-regular;
  }
  &--curator-dashboard {
    margin: 0;
    background-color: $purple-accent;
    min-height: rem(216px);
    width: 100%;
    padding: $spacing-regular;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $spacing-smaller;
    & h4 {
      display: table-cell;
      color: $white;
      text-align: right;
      vertical-align: middle;
    }
  }
  &--facilitator-dashboard {
    margin: 0;
    background-color: $purple-accent;
    min-height: rem(200px);
    width: 100%;
    padding: $spacing-regular;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & h4 {
    display: table-cell;
    color: $white;
    text-align: center;
    vertical-align: middle;
  }
}

.project-list-temp-teacher-fac {
  margin-top: 2.5vh;
  @include lg {
    padding-left: 0px !important;
  }

  .h6--semi-strong {
    @include sm {
      margin-left: 5vw;
    }

    a {
      font-size: 16px !important;
    }
  }
}

.project-list-mid-title {
  padding-left: 0px !important;
}

.use-agreement-form {
  flex-wrap: wrap;
  justify-content: space-between;

  div.Mui-error input,
  div.Mui-error fieldset {
    border-color: red !important;
  }

  p.form-field__error {
    width: 318px !important;
    background-color: $white;
  }

  p.form-field__error.skinny {
    width: 150px !important;
    background-color: $white;
  }

  &__title {
    width: rem(609px);
    height: rem(24px);
    font-family: Calibre;
    font-size: rem(19px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.26;
    letter-spacing: rem(0.1px);
    color: #2d2d2d;
    margin-bottom: rem(10px);
  }

  input {
    margin: rem(10px) 0;
    height: rem(40px);
  }

  fieldset {
    border-width: 0px;
    height: 45px;
    display: none;
  }

  .Mui-focused fieldset {
    border-width: 2px;
    margin-top: 2.5px !important;
  }

  & label {
    position: relative;
    top: rem(25px);
  }

  & label.Mui-focused,
  label[data-shrink='true'] {
    top: rem(15px);
  }

  & legend {
    position: relative;
    top: rem(-5px);
  }

  label.Mui-error {
    color: red;
  }

  input,
  select {
    border: rem(2px) solid #bdbdbd;
    padding-left: rem(10px);
    font-family: Calibre;

    &:focus {
      border: rem(2px) solid $wa-mui-blue;
    }

    &.error {
      border: rem(2px) solid $red-accent;
    }
  }

  &__email,
  &__cell,
  &__add1,
  &__add2 {
    flex-basis: 49%;
    min-width: rem(290px);

    & input[class^='Mui'],
    & div[class^='Mui'] {
      flex-basis: 49%;
      min-width: rem(290px);
    }
  }

  &__email {
    .Mui-focused legend {
      width: rem(88px) !important;
    }
  }

  &__cell {
    .Mui-focused legend {
      width: rem(80px) !important;
    }
  }

  &__add1,
  &__add2 {
    .Mui-focused legend {
      width: rem(83px) !important;
    }
  }

  &__state {
    margin: 25px 10px 0px 10px;
    height: rem(41px);
    width: rem(80px);
    background-color: $white;
    border-radius: 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url('https://cdn1.iconfinder.com/data/icons/feather-2/24/chevron-down-16.png') white
      no-repeat 98.5% !important;
    background: url('https://cdn1.iconfinder.com/data/icons/feather-2/24/chevron-down-16.png') white
      no-repeat calc(100% - 10px) !important;
  }

  &__city {
    flex-grow: 2;
    min-width: rem(350px);

    & input[class^='Mui'],
    & div[class^='Mui'] {
      flex-grow: 2;
      min-width: rem(350px);
    }

    .Mui-focused legend {
      width: rem(30px) !important;
    }
  }

  &__zip {
    min-width: rem(100px);

    & input[class^='Mui'],
    & div[class^='Mui'] {
      min-width: rem(100px);
    }

    .Mui-focused legend {
      width: rem(53px) !important;
    }
  }

  &__modal-trigger {
    position: relative;
    top: -6.5px;
    width: rem(140px);
    height: rem(20px);
    font-family: Calibre;
    font-size: rem(17px);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: rem(0.2px);
    color: #0076BB;
  }

  &__button {
    width: rem(204px);
  }
}

//
.use-agreement-form-v2 {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  div.Mui-error input,
  div.Mui-error fieldset {
    border-color: red !important;
  }

  p.form-field__error {
    width: 318px !important;
    background-color: $white !important;
  }

  p.form-field__error.skinny {
    width: 150px !important;
    background-color: $white !important;
  }

  &__title {
    //width: rem(609px);
    height: rem(24px);
    font-family: Calibre;
    font-size: rem(19px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.26;
    letter-spacing: rem(0.1px);
    color: #2d2d2d;
    margin-bottom: rem(10px);
  }

  input {
    margin: rem(10px) 0;
    height: rem(40px);
  }

  fieldset {
    border-width: 0px;
    height: 45px;
    display: none;
  }

  .Mui-focused fieldset {
    border-width: 2px;
    margin-top: 2.5px !important;
  }

  & label {
    position: relative;
    top: rem(25px);
  }

  & label.Mui-focused,
  label[data-shrink='true'] {
    top: rem(15px);
  }

  & legend {
    position: relative;
    top: rem(-5px);
  }

  label.Mui-error {
    color: red;
  }

  input,
  select {
    border: rem(2px) solid #bdbdbd;
    padding-left: rem(10px);
    font-family: Calibre;

    &:focus {
      border: rem(2px) solid $wa-mui-blue;
    }

    &.error {
      border: rem(2px) solid $red-accent;
    }
  }

  &__email,
  &__cell,
  &__add1,
  &__add2,
  &__fname,
  &__lname {
    flex-basis: 49%;
    min-width: rem(350px);
    border-color: red;

    & input[class^='Mui'],
    & div[class^='Mui'] {
      flex-basis: 49%;
      min-width: rem(350px);
    }
  }

  &__auam-email,
  &__auam-cell,
  &__auam-add1,
  &__auam-add2,
  &__auam-fname,
  &__auam-lname {
    flex-basis: 49%;
    min-width: rem(250px);
    border-color: red;

    & input[class^='Mui'],
    & div[class^='Mui'] {
      flex-basis: 49%;
      min-width: rem(250px);
    }
  }

  &__email {
    .Mui-focused legend {
      width: rem(88px) !important;
    }
  }

  &__cell {
    .Mui-focused legend {
      width: rem(80px) !important;
    }
  }

  &__add1,
  &__add2 {
    .Mui-focused legend {
      width: rem(83px) !important;
    }
  }

  &__state, &__auam-state {
    margin: 25px 2px 0px 2px;
    height: rem(41px);
    width: rem(90px);
    background-color: $white;
    border-radius: 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url('https://cdn1.iconfinder.com/data/icons/feather-2/24/chevron-down-16.png') white
      no-repeat 98.5% !important;
    background: url('https://cdn1.iconfinder.com/data/icons/feather-2/24/chevron-down-16.png') white
      no-repeat calc(100% - 10px) !important;
  }

  &__city {
    flex-grow: 2;
    min-width: rem(420px);

    & input[class^='Mui'],
    & div[class^='Mui'] {
      flex-grow: 2;
      min-width: rem(420px);
    }

    .Mui-focused legend {
      width: rem(30px) !important;
    }
  }

  &__zip, &__auam-zip {
    min-width: rem(150px);

    & input[class^='Mui'],
    & div[class^='Mui'] {
      min-width: rem(150px);
    }

    .Mui-focused legend {
      width: rem(53px) !important;
    }
  }

  &__modal-trigger {
    position: relative;
    top: -6.5px;
    width: rem(140px);
    height: rem(20px);
    font-family: Calibre;
    font-size: rem(17px);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: rem(0.2px);
    color: #0076BB;
  }

  &__button {
    width: rem(204px);
    height: rem(50px);
    border: solid 2px #1d9ede;
    background-color: white;
    color: #1d9ede;
    font-size: rem(17px);
    padding-top: rem(10px);
  }
}

.use-agreement-form-p1 {
  width: 80%;
  margin: 0 auto;
  margin-bottom: rem(30px);

  div[class^='FormCheckbox'] {
    margin: 30px 0;
    //color: red;
  }

  p.use-agreement-form-v2__title {
    margin-top: 30px;
    margin-bottom: -25px;
  }
}

.submit-use-agreement-form-v2 {
  width: 80%;
  margin: 0 auto;
  margin-top: -35px;
  button {
    margin-top: 10px;
  }
}

.project-progress__description .use-agreement-form {
  div[class='use-agreement-form-root'] {
    background-color: #f7f7f7 !important;

    p.form-field__error {
      background-color: #f7f7f7 !important;
    }

    div.MuiTextField-root {
      background-color: #f7f7f7;

      div.MuiInputBase-root {
        background-color: white !important;
      }
    }
  }
}
