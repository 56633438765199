@import '../ui/settings/settings.scss';

.public-resources {
  &__hero {
    background-color: $white;
    height: 100%;
    display: flex;
    align-items: top;
    padding: rem(200px) rem(80px) $spacing-regular;
    @include md {
      display: block;
      padding: $spacing-large 0 0 0;
    }
    &--copy {
      width: 50%;
      margin-right: 10%;
      display: inline-block;
      max-width: rem(600px);
      @include md {
        width: 100%;
        display: block;
        padding: $spacing-regular;
      }
      & strong {
        color: $public-blue;
      }
    }
    &--header {
      width: 40%;
      height: 100%;
      display: inline-block;
      margin-right: 0;
      @include md {
        padding-left: $spacing-regular;
      }
      & h1.h1--strong {
        color: $public-blue;
        font-size: rem(70px);
        line-height: rem(60px);
      }
    }
  }
}

.resource-list {
  &__category {
    width: 33%;
    display: inline-flex;
    flex-wrap: wrap;
    padding: 0 $spacing-smaller rem(70px) $spacing-smallest;
    @include md {
      width: 50%;
      display: inline-flex;
      flex-wrap: wrap;
      padding: 0 $spacing-smaller rem(70px) $spacing-smallest;
    }
    @include sm {
      width: 100%;
      padding: 0 0 rem(70px) 0;
    }
    &--image {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: auto;
      background-color: $purple-accent;
      text-align: center;
      align-content: center;
      & h6.h6--semi-strong {
        width: 100%;
        color: $white;
      }
    }
    &--header {
      padding: $spacing-small 0;
      display: block;
      width: 100%;
    }
    &--links {
      & a {
        display: block;
        width: 100%;
        padding: $spacing-smallest 0;
      }
    }
  }
}

// CUSTOM OVERRIDES
.body__resource-list {
  margin-top: 0;
  padding: $spacing-large rem(75px) $spacing-largest;
  @include md {
    padding: $spacing-large $spacing-regular;
  }
}
.resources__intro {
  max-width: 60%;
  margin: auto;
  padding-top: $spacing-regular;
  @include md {
    max-width: 100%;
  }
}
.container__resource-block {
  @include md {
    width: 50%;
    padding: $spacing-small $spacing-small;
    margin: 0;
  }
  @include sm {
    padding: 0;
    width: 100%;
    margin-bottom: $spacing-regular;
  }
}
