    @import '../../ui/settings/settings.scss';

.review__stack {
    width: 100%;
    min-height: 24px;
    // margin-top: 10px;
    &-h5 {
        margin-bottom: 3px;
        padding:0px;
        width: 100%;
    }
    &-h5-message {
        margin-bottom: 3px;
        padding:0px;
    }
}
.review__stack {
    &-text {
    margin-right: rem(8px);
    }
    &-award-section{
        width: 100%;
        display: flex;
    }
    &-curator-award-section {
        width: 100%;
        display: flex;
        padding-top: 10px;
    }
    &-award-buttons {
        float: right;
        display: flex;
    }
    &-curator-award-buttons {
        display: flex;
        // margin-bottom: rem(5px);
        // min-height: rem(28px);
        width: 100%;
    }
    &-curator-reward {
        float: right;
        width: 100%;
    }
    &-reward {
        float: right;
        width: 100%;
    }
    &-award-button__icon{
        cursor: pointer;
        margin-right: 8px;
        height: fit-content;
        width: 80px;
        padding-top: 5px;
        font-size: 14px;
        text-align: center;
        border-radius: 30px;
        box-sizing: border-box;
        color: #2D2D2D;
        &--selected {
            text-align: center;
            cursor: pointer;
            margin-right: 5px;
            height: fit-content;
            width: 80px;
            padding-top: 5px;
            border: 2px solid #008160;
            color: #008160;
            font-size: 14px;
            box-sizing: border-box;
            border-radius: 30px;
            line-height: 1.2;
            &:hover {
                color: #008160;
                // border: 0px;
            }
        }
        &:hover {
            color: #008160;
            border: 0px;
        }
    }
}
.review__stack-award-button__icon.award-chips--disabled {
    opacity: 0.5;
    cursor: default;
    &:hover {
        color: #000000;
    }
}
.review__stack-award-button__icon--selected.award-chips--disabled {
    opacity: 0.5;
    cursor: default;
    &:hover {
        color: #008160;
        border: 2px solid #008160;
    }
}
.curator-review-award--selected {
    cursor: pointer;
    float: right;
    margin-right: 5px;
    color:#008160;
    border: 2px solid #008160;
    height: 30px;
    width: 30px;
    border-radius: 50px;
    padding-left: 3px;
    padding-top: 3px;
    &:hover {
        border:0px;
    }
}

.photo-details-action-bar {
    display: flex;
    justify-content: center;
    .review__stack-award-button__icon--selected > button{
        padding-bottom: 5px;
    }
}

.review__stack-curator-award-buttons > .review__stack-award-button__icon--selected,
.review__stack-curator-award-buttons > .review__stack-award-button__icon {
    line-height: inherit;
    margin-left: 8px;
    margin-right: 0;
    button > svg {
        margin-bottom: 3px;
    }
}

.review__stack-curator-award-buttons {
    .review__stack-award-button__icon--selected {
        display: flex;
        align-items: center;
        justify-content: center;
        // padding-bottom: 4px;
        // margin-top: -2px;
        span {
            display: flex;
            align-items: center;
            margin-top: 2px;
            svg {
                margin-top: 2px;
            }
        }
    }
}