@import '../../ui/settings/settings.scss';
.publication-section {
  margin-top: $spacing-medium;
  &__heading {
    margin-bottom: $spacing-small;
  }
  &__body {
    &--descriptions {
      margin-bottom: $spacing-medium;
    }
    &--sub-descriptions {
      margin-top: 22px;
      margin-bottom: 22px;
    }
  }
  &__modal-trigger {
    font-size: 1.0625rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: 0.0125rem;
    color: #0076BB;
  }
  &__sub-sections {
    margin-bottom: $spacing-medium;
    &--heading {
      margin-bottom: rem(8px);
    }
  }
  &__checkbox {
    margin-top: 3px;
  }
  &__btn {
    margin-top: 12px;
  }
}