@import '../ui/settings/settings.scss';

.body__join-class {
  text-align: center;
  margin: $spacing-largest 0;
  & .working-assumptions_monogram {
    width: max-content;
    margin: 0 auto $spacing-regular;
  }
}
.join-class {
  &__message {
    max-width: rem(700px);
    margin: auto;
    padding: $spacing-regular 0 0 0;
  }
  &__buttons {
    padding: $spacing-regular 0 0 0;
  }

  &--centered-logo {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 1rem 0 3rem;
  }
}
