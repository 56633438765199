@import '../settings/settings.scss';

// // BUTTONS
// .button {
//   font-family: $sans-serif-bold;
//   font-size: $size-h3;
//   &__icon {
//     // ICON BUTTONS
//     max-width: max-content;
//     display: inline-block;
//     float: left;
//     padding-right: $spacing-smaller;
//   }
//   &__title {
//     width: max-content;
//     margin: auto;
//     & h4.h4--semi-strong {
//       padding-bottom: 0;
//       float: left;
//       top: 0;
//     }
//   }
// }


// .btn-primary:not(:disabled):not(.disabled):active:focus{
//   box-shadow:0 0 0 0.2rem rgb(38 143 255 / 50%)
// }


// .btn-primary {
//   position: relative;
//   border-radius: $radius-none;
//   border: none;
//   font-family: $sans-serif-bold;
//   letter-spacing: $spacing-wide;
//   border: rem(2px) solid $blue-accent;
//   font-size: $size-h4;
//   font-weight: bold;
//   background-color: $blue-accent;
//   transition: $transition-fast;
//   padding: rem(12px) $spacing-small;

//   &:hover,
//   &:active {
//     border: rem(2px) solid $blue-hover !important;
//     background-color: $blue-hover !important;
//   }
//   &:disabled {
//     color: $dark-grey !important;
//     border: rem(2px) solid $light-grey;
//     background-color: $light-grey;
//   }
// }

// .btn:focus-visible{
//   background-color: transparent;
//   color: $blue-accent;
//   border-color: transparent;
// }

// .btn-outline-dark,
// .btn-outline-light {
//   position: relative;
//   border-radius: $radius-none;
//   font-family: $sans-serif-bold;
//   letter-spacing: $spacing-wide;
//   font-size: $size-h4;
//   font-weight: bold;
//   transition: $transition-fast;
//   border: 2px solid $blue-accent;
//   color: $blue-accent;
//   transition: $transition-fast;
//   padding: rem(12px) $spacing-small;
//   @include md {
//     width: 100%;
//   }
//   &:hover {
//     color: $blue-accent !important;
//     background: $transparent-blue !important;
//     border: 2px solid $blue-accent !important;
//   }
//   &:disabled {
//     color: $light-grey;
//     background-color: transparent;
//     border: rem(2px) solid $light-grey;
//   }
// }

// .btn-outline-dark:focus {
//   box-shadow:0 0 0 0.0rem rgb(38 143 255 / 50%);
//   border: 2px solid rgb(0, 118, 187) !important;
// }

// .btn-outline-dark:not(:disabled):not(.disabled):active{
//   border: rem(2px) solid $blue-hover !important;
//   background-color: $blue-hover !important;
//   color: #fff !important;
// }


// .btn-sm {
//   padding: rem(12px) rem(16px);
//   @include md {
//     width: 100%;
//   }
//   &:disabled {
//     color: $dark-grey;
//     background-color: $light-grey;
//   }
// }
// .btn-outline-success {
//   padding: $spacing-regular auto;
//   height: rem(125px);
//   width: 100%;
//   color: $dark-grey;
//   background: $transparent-green;
//   border: rem(1px) solid $green-accent;
//   &:disabled {
//     background-color: $light-grey;
//   }
//   &:hover {
//     background: $transparent-green;
//     color: $dark-grey;
//     opacity: 0.8;
//   }
// }
// .btn-outline-danger {
//   padding: $spacing-regular auto;
//   height: rem(125px);
//   width: 100%;
//   color: $dark-grey;
//   background: $transparent-red;
//   border: rem(1px) solid $red-accent;
//   &:disabled {
//     background-color: $light-grey;
//   }
//   &:hover {
//     background: $transparent-red;
//     color: $dark-grey;
//     opacity: 0.8;
//   }
// }
// .btn-outline-default {
//   padding: $spacing-regular $spacing-small;
//   height: rem(125px);
//   width: 100%;
//   color: $dark-grey;
//   background: $white;
//   border: rem(2px) solid $light-grey;
//   border-radius: 0;
//   @include md {
//     width: 100%;
//   }
//   &:hover {
//     color: $dark-grey;
//     border: rem(2px) solid $green-accent !important;
//   }
//   &:disabled {
//     opacity: 0.5;
//     &:hover {
//       border: rem(2px) solid $light-grey;
//       color: $light-grey;
//       opacity: 0.5;
//     }
//   }
//   &--selected {
//     border: rem(2px) solid $green-accent;
//     color: $green-accent;
//   }
// }
// .outline-buttons__left {
//   &-sm {
//     width: 48.8%;
//     border-radius: $radius-none;
//     display: inline-block;
//     border: rem(2px) solid $light-grey;
//     margin-top: $spacing-small;
//     float: left;
//     margin-right: 1.2%;
//     min-height: auto;
//     height: auto;
//     @include sm {
//       width: 100%;
//     }
//     &--selected {
//       width: 49%;
//       border-radius: $radius-none;
//       display: inline-block;
//       border: rem(2px) solid $green-accent;
//       margin-top: $spacing-small;
//       float: left;
//       margin-right: 1%;
//       min-height: auto;
//       height: auto;
//       width: 49%;
//       &:disabled {
//         opacity: 0.5;
//       }
//       @include sm {
//         width: 100%;
//       }
//       &:hover {
//         opacity: 1;
//       }
//     }
//   }
//   width: 49%;
//   height: auto;
//   border-radius: $radius-none;
//   display: inline-block;
//   margin-top: $spacing-small;
//   float: left;
//   margin-right: 1%;
//   padding: $spacing-medium $spacing-small;
//   min-height: max-content;
//   @include sm {
//     width: 100%;
//   }
//   &:hover {
//     border: rem(2px) solid $green-accent;
//     opacity: 1;
//   }
//   &--selected {
//     width: 49%;
//     border-radius: $radius-none;
//     display: inline-block;
//     border: rem(2px) solid $green-accent;
//     margin-top: $spacing-small;
//     float: left;
//     margin-right: 1%;
//     height: auto;
//     padding: $spacing-medium $spacing-small;
//     min-height: max-content;
//     @include sm {
//       width: 100%;
//     }
//     &:hover {
//       opacity: 1;
//     }
//   }
// }
// .outline-buttons__right {
//   &-sm {
//     width: 50%;
//     border-radius: $radius-none;
//     display: inline-block;
//     border: rem(2px) solid $light-grey;
//     margin-top: $spacing-small;
//     float: right;
//     min-height: auto;
//     height: auto;
//     @include sm {
//       width: 100%;
//       margin-left: 0;
//       margin-top: $spacing-smaller;
//     }
//     &--selected {
//       width: 50%;
//       border-radius: $radius-none;
//       display: inline-block;
//       border: rem(2px) solid $green-accent;
//       margin-top: $spacing-small;
//       float: right;
//       min-height: auto;
//       height: auto;
//       @include sm {
//         width: 100%;
//         margin-left: 0;
//         margin-top: $spacing-smaller;
//       }
//       &:hover {
//         border: rem(2px) solid $green-accent;
//         opacity: 1;
//       }
//     }
//   }
//   width: 49%;
//   height: auto;
//   border-radius: $radius-none;
//   display: inline-block;
//   margin-top: $spacing-small;
//   float: right;
//   margin-left: 1%;
//   padding: $spacing-medium $spacing-small;
//   @include sm {
//     width: 100%;
//     margin-left: 0;
//     margin-top: $spacing-smaller;
//   }
//   &:hover {
//     border: rem(2px) solid $green-accent;
//     opacity: 1;
//   }
//   &--selected {
//     width: 49%;
//     border-radius: $radius-none;
//     display: inline-block;
//     border: rem(2px) solid $green-accent;
//     margin-top: $spacing-small;
//     float: right;
//     margin-left: 1%;
//     height: auto;
//     padding: $spacing-medium $spacing-small;
//     @include sm {
//       width: 100%;
//       margin-left: 0;
//       margin-top: $spacing-smaller;
//     }
//     :hover {
//       opacity: 1;
//     }
//   }
// }

// // ======== SPECIAL USE CASES ======== //

// // HOVER SELECTED OUTLINE BUTTON:
// button.outline-buttons__right--selected.btn.btn-outline-default,
// button.outline-buttons__left--selected.btn.btn-outline-default {
//   border: 2px solid #008160;
// }

// // TITLE & SUBTITLE BUTTONS:
// .outline-button .button__bold-title p.p--strong {
//   line-height: 1;
// }
// .outline-button .button__subtitle p {
//   line-height: 1;
//   margin-top: $spacing-smaller;
// }

// .mb_3p{
//   margin-bottom:4px !important;
// }

// button.outline-buttons--selected,
// button.outline-buttons.award--selected {
//   border: rem(2px) solid $green-accent;
// }

// button.outline-buttons.award,
// button.outline-buttons.award--selected {
//   @media (max-width: 750px) {
//     padding-left: 0rem;
//     padding-right: 0rem;
//     text-align: center;

//     .button__title {
//       max-width: 85%;
//       text-align: center;

//       .button__icon {
//         float: none;
//       }
//     }

//     @media (max-width: 359px) {
//       .award {
//         width: 85%;
//         font-size: 14px;
//       }
//     }

//     @media (min-width: 360px) {
//       .award {
//         width: 80%;
//         font-size: 15px;
//       }
//     }
//   }
// }

// .custom_size_15p {
//   width: 15%;
// }

button.outline-buttons--selected,
button.outline-buttons.award--selected,
.outline-buttons__left-sm--selected,
.outline-buttons__right-sm--selected,
button.outline-buttons--selected:hover,
button.outline-buttons.award--selected:hover,
.outline-buttons__left-sm--selected:hover,
.outline-buttons__right-sm--selected:hover,
button.outline-buttons:hover,
.outline-buttons__left-sm:hover,
.outline-buttons__right-sm:hover,
.outline-buttons__left--selected,
.outline-buttons__right--selected {
  border: 0.125rem solid #008160 !important;
  color: #000 !important;
}

button.outline-buttons,
.outline-buttons__left-sm,
.outline-buttons__right-sm,
.outline-buttons__left,
.outline-buttons__right {
  border: .125rem solid #d5d5d7 !important;
  color: #000 !important;
}
