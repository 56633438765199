@import '../settings/settings.scss';

// Main Modals
.modal {
  backdrop-filter: blur(2px);
  background-color: rgba(45, 45, 45, 0.5);
}
.modal-body {
  padding-top: 1rem;
  padding-bottom: 2.1rem;
}
.invite-student {
  &__copy-link {
    width: 70%;
    display: inline-block;
    // padding-bottom: $spacing-medium;
    margin-top: $spacing-small;
    &--link {
      border: $border-thin-grey;
      padding: rem(13px) $spacing-small;
    }
    &--button {
      width: 30%;
      display: inline-block;
    }
  }
  &__disclaimer {
    padding: $spacing-medium 0 0;
  }
  &__description {
    padding-bottom: $spacing-small;
  }
  &__age-buttons {
    width: 100%;
    display: inline-block;
  }
  &__model-age {
    margin-top: $spacing-medium;
  }
  &__model-name,
  &__model-age {
    & h3 {
      font-size: 1.125rem;
    }
    & h5.h5--semi-strong {
      margin-bottom: rem(-15px);
    }
    & h4.h4--semi-strong {
      padding-bottom: 0;
    }
  }
}

// MODEL RELEASE
.model-release {
  &__form-parent {
    padding-top: $spacing-medium;
    & h3 {
      font-size: 1.125rem;
    }
    & h3.h3-semi-strong {
      font-weight: bold;
      padding-bottom: rem(24px);
    }
  }
  &__form-field {
    display: inline-block;
    width: 100%;
  }
  &__upload-button {
    padding: 0;
  }
  &__dropdown--icon {
    div[role='tooltip'] {
      position: absolute !important;
      z-index: 1;
      top: -37px !important;
      left: 67px !important;
      min-width: 200px !important;
      @include md {
        left: -11rem !important;
      }
    }
  }
}

.marg_bott_20p{
  margin-bottom:-20px;
}

#mt_10{
  margin-top:-10px;
}

.mr-ua__uploader__button {
  width: 100%;
  display: flex;
}

.model-release-file-error {
  background-color: #ffffff !important;
}
// SEND PDF MODAL
.send-pdf {
  &__links {
    width: 70%;
    display: inline-block;
    padding-bottom: $spacing-small;
    &--downloads {
      padding-top: $spacing-smallest;
      display: inline-flex;
      & h4.h4--semi-strong.link {
        padding: 0 $spacing-small 0 0;
      }
      & svg {
        vertical-align: middle;
      }
    }
  }
  &__description {
    padding: $spacing-small 0;
  }
  &__submit {
    & .container__uploader {
      text-align: left;
      margin-top: $spacing-small;
      & .uploader__button {
        margin-left: 0;
        display: flex;
      }
    }
  }
}

.mr-upload-file-name {
  margin: auto;
  margin-left: 20px;
  width: 430px;
}

// DELETE & CHANGE USER MODALS
.delete-user,
.delete-event,
.change-user {
  &__description {
    padding: 0 0 $spacing-small 0;
  }
  &__button {
    display: flex;
    align-items: center;
    & h4.h4--semi-strong.link {
      padding: 0px $spacing-small;
      width: auto;
    }
  }
}
.send-message {
  &__button {
    margin-top: $spacing-regular;
    display: flex;
    align-items: center;
    & h4.h4--semi-strong.link {
      padding: 0px $spacing-small;
      width: auto;
    }
  }
}

.send-message-updated {
  &__button {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    margin-bottom:2px;
    & h4.h4--semi-strong.link {
      padding: 0px $spacing-small;
      width: auto;
    }
  }
}

.message-body{
  margin-bottom: 2rem;
}

// USER DETAILS MODAL
.user-details {
  &__header {
    padding-top: 1.8rem;
    padding-bottom: rem(6px);
    &__title {
      h2 {
        padding-bottom: 0;
      }
    }
  }
  &__body {
    padding-bottom: 1rem;
  }
  &__description {
    padding: $spacing-small 0;
    display: block;
    width: 100%;
    & p.body {
      line-height: $line-height-base;
    }
  }
  &__links {
    display: block;
    // padding: $spacing-medium 0 $spacing-regular;
    border-bottom: $border-thin-grey;
    &.no-border {
      border-bottom: none;
    }
    &--website {
      width: 50%;
      display: inline-block;
      & a,
      & h4.h4--semi-strong {
        width: max-content;
        max-width: max-content;
        display: flex;
      }
      @include md {
        width: 100%;
        flex-wrap: wrap;
        & a,
        & h4.h4--semi-strong {
          width: max-content;
          display: flex;
          max-width: max-content;
        }
      }
    }
    &--social {
      width: 50%;
      display: inline-block;
      & a,
      & h4.h4--semi-strong {
        width: max-content;
        max-width: max-content;
        display: flex;
      }
      @include md {
        width: 100%;
        padding-top: $spacing-medium;
        & a,
        & h4.h4--semi-strong {
          width: max-content;
          max-width: max-content;
          display: flex;
        }
      }
    }
  }
  &__contact {
    display: block;
    & h4.h4--semi-strong {
      font-size: 1.25rem;
      padding-bottom: 0.4rem;
    }
    & h5 {
      font-size: 1rem;
      padding-bottom: 0;
    }
    &--email {
      width: 50%;
      display: inline-block;
      & a,
      & h4.h4--semi-strong {
        width: max-content;
        max-width: max-content;
        display: flex;
        font-size: 1.125rem;
      }
      @include md {
        width: 100%;
        & a,
        & h4.h4--semi-strong {
          width: max-content;
          max-width: max-content;
          display: flex;
        }
      }
    }
    &--mobile {
      width: 50%;
      display: inline-block;
      & a,
      & h4 {
        font-size: 1.125rem;
        &.h4--semi-strong {
          width: max-content;
          max-width: max-content;
          display: flex;
        }
      }
      @include md {
        width: 100%;
        & a,
        & h4.h4--semi-strong {
          width: max-content;
          max-width: max-content;
          display: flex;
        }
      }
    }
  }
}

// DOWNLOAD FILES MODAL
.download-file {
  &__links {
    &--link {
      width: fit-content;
      & h4.h4--semi-strong.link {
        text-align: left;
      }
    }
  }
}

// SCHOOL DETAILS MODAL
.school-details {
  &__links {
    display: block;
    margin-top: -9px;
    &--website,
    &--demographics,
    &--type,
    &--district {
      width: 50%;
      display: inline-block;
      padding-bottom: 1rem;
    }
    & a {
      text-decoration: underline;
    }
    & h3 {
      padding-bottom: 2px;
    }
    .h5 {
      font-size: 1.125rem;
    }
  }
  &__demograph-link {
    overflow-wrap: break-word;
  }
  &__program-info {
    display: block;
    margin-top: -3px;
    &--date-joined,
    &--mobile,
    &--awards,
    &--photographs {
      width: 50%;
      padding-top: rem(8px);
      display: inline-block;
      padding-bottom: rem(6px);
      .h5 {
        font-size: 1.125rem;
      }
    }
  }
  &__class-info {
    display: block;
    // margin-top: $spacing-regular;
    // padding: $spacing-regular 0 0;
    // border-top: $border-thin-grey;
    &--grade-level,
    &--teaching-artist {
      width: 50%;
      padding-top: $spacing-small;
      display: inline-block;
    }
  }
  &__modal-body {
    padding-bottom: rem(27px) !important;
  }
  &__view-more {
    text-decoration: underline;
  }
}

// SEND SUMMARY MODAL
.delete-user__button h4.h4--semi-strong.link {
  text-align: left;
}

// EXTRA OVERRIDES
.modal-content .modal-header .btn-close {
  color: $navigation-grey;
  position: relative;
  right: 0;
  top: 0;
}
.model-release__buttons {
  margin-top: $spacing-medium;
}
.model-release__resend-buttons {
  margin-top: rem(24px);
  display: flex;
}
.model-release__resend-buttons .btn-primary {
  margin-right: rem(16px);
}
.model-release__resend-buttons .h4--semi-strong.link {
  text-align: left;
  padding-top: 1rem;
}
.resend-model__request-field {
  width: 60%;
}
.resend-model__request-upload-file {
  max-width: rem(600px);
}

.resend-model__request-field {
  width: 60%;
}
.resend-model__request-upload-file {
  max-width: rem(600px);
}
.model-release__resend-buttons {
  margin-top: rem(24px);
}
.resend-model__request-field {
  width: 60%;
}
.resend-model__request-upload-file {
  max-width: rem(600px);
}
.invite-student .invite-student .model-release {
  &__form {
    display: inline-block;
    width: 100%;
    margin-bottom: $spacing-small;
  }
  &__buttons {
    margin-top: $spacing-small;
  }
}
span.submit__button--success {
  @include animation('fade-in-out 6s forwards');
  padding: $spacing-smaller 0 0 $spacing-small;
  color: $green-accent;
  display: inline-block;
  position: absolute;
}
span.copy-link__button--success {
  @include animation('fade-in-out 6s forwards');
  padding: 10px 0 0 5px;
  color: $green-accent;
  display: inline-block;
  position: relative;
  @include xs {
    padding: 10px 0 0 5px;
  }
}
.invite-student__copy-link--button .btn-sm {
  margin-top: rem(-4px);
  margin-left: $spacing-medium;
  width: rem(150px);
}
@include keyframes(fade-in-out) {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.add-border {
  width: 100%;
  border-bottom: $border-thin-grey;
}

.photo-detail-modal {
  max-width: 100vw;
  max-height: 100vh;
  padding-left: 0 !important;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: rem(1px);
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .modal-dialog {
    min-height: rem(100vh);
    min-width: rem(100vw);
    margin-bottom: auto;

    .modal-content {
      margin-top: rem(-2em);
      min-height: rem(100vh);
      min-width: rem(100vw);

      button.black-text {
        p {
          color: $black;
          font-size: rem(16px);
        }
      }

      .modal-header {
        height: rem(40px);
        position: sticky;
        top: 0;
        background-color: $white;
        z-index: 5;
        width: rem(100vw);
        border-bottom: 1px solid #dee2e6;
        &-timestamp {
          font-size: rem(15px);
          font-family: $sans-serif;
          text-align: right;
        }

        button.btn-close {
          position: absolute;
          top: rem(16px);
          left: (1.5vw);
          right: unset;
        }
      }

      .modal-photo-container {
        width: rem(100vw);
        max-height: rem(84vh);
        height: rem(84vh);
        background-color: #020202;
        text-align: center;
        padding: rem(16px);
        position: relative;

        .helper {
          display: inline-block;
          height: 100%;
          vertical-align: middle;
        }

        img {
          max-height: rem(70vh);
          max-width: rem(85vw);
          vertical-align: middle;
          cursor: zoom-in;
        }

        &-pulse-loader-wrapper {
          z-index: 1002;
          display: inline-block;
        }

        button {
          background-color: rgba(200, 200, 200, 0.1);
          color: #f7f7f7f7;
          width: rem(56px);
          height: rem(128px);
          border-color: rgba(200, 200, 200, 0.1) !important;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);

          &.get-previous-photo-btn {
            left: 0;
            &__disabled {
              left:0;
              opacity: 0.1;
              cursor: not-allowed;
              border-color: rgba(200, 200, 200, 0.1) !important;
            }
          }

          &.get-next-photo-btn {
            right: 0;
            &__disabled {
              right:0;
              opacity: 0.1;
              cursor: not-allowed;
            }
          }
        }
      }

      .collection-modal-photo-container {
        width: rem(100vw);
        max-height: 100vH;
        background-color: #ffffff;
        text-align: center;
        padding: rem(16px);
        position: relative;

        .helper {
          display: inline-block;
          height: 100%;
          vertical-align: middle;
        }

        img {
          max-height: rem(70vh);
          max-width: rem(85vw);
          vertical-align: middle;
          cursor: zoom-in;
          margin-top: rem(13px);
        }

        &-pulse-loader-wrapper {
          z-index: 1002;
          display: inline-block;
        }

        button {
          background-color: transparent;
          color: #000000;
          width: rem(56px);
          height: rem(128px);
          border-color: transparent;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);

          &.get-previous-photo-btn {
            left: 0;
            &__disabled {
              left:0;
              opacity: 0.1;
              cursor: not-allowed;
              border-color: rgba(200, 200, 200, 0.1) !important;
            }
          }

          &.get-next-photo-btn {
            right: 0;
            &__disabled {
              right:0;
              opacity: 0.1;
              cursor: not-allowed;
            }
          }
        }
      }
    }

    .btn-close {
      position: absolute;
      top: rem(0.5vh);
      left: rem(2vw);
      color: $wa-mui-blue;
      font-size: rem(28px);
      font-weight: 200;
      background: transparent url("../../assets/svgs/x.svg") no-repeat;
      opacity: 1;
      width: 8rem;
      height: rem(12px);
      span::after {
        content: ' Close Presentation';
        font-size: rem(12px);
        vertical-align: middle;
        font-weight: 600;
      }
    }
  }

  &-body {
    width: rem(768px);
    margin: 0 auto;
  }

  &-footer {
    position: relative;
    bottom: rem(-40px);
    left: rem(-40px);
    min-width: rem(100vw);

    &-download {
      display: flex;
      flex-direction: column;
    }
  }
}


.use-agreement-terms-modal {
  width: 717px;
  height: 366px;
  font-family: LyonText;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.1px;
  color: #2d2d2d;
}

.model-release-type-buttons-containers {
  width: 100%;
}

.model-release-type-buttons-containers .p--strong {
  font-size: rem(18px);
}

.model-release-type-buttons {
  height: auto;
  margin-bottom: rem(15px);
}

.fsm-screen-resolution-text {
  border-radius: 6px;
  // bottom: 20px;
  font-size: 15px;
  bottom: 5px;
  position: relative;
  font-weight: bolder;
  text-align: right;
}

.fsm-screen-resolution-container {
  top: 6px;
  width: 100%;
  position: absolute;
  text-align: center;
}

.fsm-photo-comment-header-section{
  display: flex;
  position: absolute;
  right: 10px;
  // top: 0;
  align-self: center;
  height: 2.2rem;
}

.fsm-photo-comment-header-section-icon{
  width: auto ;
  text-align: right;
  position: relative;
  align-self: center;
  border-right: 1px solid #dee2e6;
  height: 1.5rem;
  padding-right: 8px;
  float: left;
}

.fsm-photo-comment-header-section-timestamp {
  width: auto;
  padding-left: 15px;
}

.edit-award{
  &__description {
    margin-left: rem(27px);
  }
  &__link {
    color: #0076bb;
    cursor: pointer;
    text-decoration: underline;
  }

  &__section {
    display: flex;
  }
  &__modal-description {
    margin-bottom: $spacing-medium;
  }
  &__stack-award-button__icon{
    cursor: pointer;
    margin-right: 5px;
    padding: 2px 15px;
    font-size: 12px;
    text-align: center;
    &--selected {
      text-align: center;
      cursor: pointer;
      margin-right: 5px;
      border-radius: 50px;
      padding: 2px 15px;
      border: 2px solid #008160;
      color: #008160;
      font-size: 12px;
    }
  }
  &__submit-buttons {
    margin-top: $spacing-medium;
    display: flex;
  }

}

.edit-award__submit-buttons h4.h4--semi-strong.link {
  text-align: left;
  margin-left: 32px;
  padding-top: 1rem;
}

.awards{
  &__list-sction {
    margin-top: rem(12px);
  }
  &__final-section {
    margin-top: $spacing-medium;
    &--result {
      margin-top: $spacing-small;
    }
  }
}

.reopen-review-modal{
  &__section{
    &--question {
      margin-top: $spacing-small;
      margin-bottom: $spacing-small;
    }
  }
  &__section p{
    margin-bottom: $spacing-smallest;
  }

}

.submit-your-review-modal{
  &__section p{
    margin-bottom: $spacing-smallest;
  }
}

.curator-comments {
  &__button {
    margin-bottom: $spacing-smaller;
  }
}

figure.item {
  display: inline-block;
  height: auto;
}

figcaption.caption {
  font-family: 'Calibre';
  margin-top: rem(21px);
  color: #2d2d2d;
  font-size: 1.125rem;
  line-height: 1.2rem;
  //max-width: 53vw;
  text-align: left;
}

.photo-caption {
  padding-bottom: $spacing-small;
  margin: 28px auto auto;
  text-align: justify;
  max-width: 85vw;
  width: fit-content;
  @media only screen and (min-width: 1024px) {
    max-width: 65vw;
  }
}

.photo-caption > blockquote {
  font-family: 'Lyon';
  font-size: 1.25rem;
  line-height: 1.45rem;
  max-width: 800px;
}

.h2 {
  &--semi-strong {
    padding-bottom: 0px;
  }
}

.modal-title {
  & h4 {
    padding-bottom: 0px;
  }
}

.school-details__program-info--awards {
  padding-top: 0.6875rem;
  margin-bottom: 4px;
}

.school-details__links--website, .school-details__links--demographics {
  margin-top: 4px;
}

.padding-top-5 {
  padding-top: 5px;
}

.padding-bottom-1 {
  padding-bottom: 10px;
}

.remove-alert {
  color: red;
  border: 1px solid red;
  padding: 1rem 1rem 2.5rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.remove-alert-0_6 {
  color: red;
  border: 1px solid red;
  padding: 1rem 1rem 2.5rem;
  margin-top: 20px;
  margin-bottom: 2rem;
}

.container__sticky-header--empty-div {
  display: flex;
}

.models-list {
  &__model-name {
    width: 20%;
  }
  &__delete-model-link{
    width: 80%;
    text-align: right;
  }
}
.toast-header > button {
  position: relative !important;
  top: -6px !important;
}

.modal[role='dialog'], .MuiModal-root {
  z-index: 1350 !important;
}