@import '../settings/settings.scss';

.project-progress {
  &__step-text {
    font-size: rem(16px) !important;
    font-weight: 600;
    font-family: Calibre;
  }
  &__step-icon-text{
    font-size: rem(32px) !important;
  }
  &__step-content{
    &--root {
      margin-left: 16px !important;
      max-width: rem(600px);
    }
  }
  &__steps-section {
    font-family: Calibre;
    padding-left: $spacing-small;
  }
  &__submitted {
    min-height: rem(200px);
    & p{
      margin-bottom: 8px;
    }
  }
  
  &__alert {
    color: $red-accent;
    &--success {
      color: $green-accent;
    }
    &--warning {
      color: $red-accent;
    }
  }
  
  &__publishing-buttons {
    display: inline-block;
    width: 100%;
    margin-bottom: $spacing-regular;
    background-color: #ffffff;
  }
  
  
  &__accepted-permission--permission-data {
    color: $green-accent;
  }
  &__accepted-permission {
    padding: $spacing-small 0;
  }

  &__submit-project-modal {
    .modal-dialog {
      min-width: 60vw; 
    }
  }
  &__submit-project-modal-body {
    width: 90%;
  }
  &__submit-project {
    &__checkboxes {
      margin-bottom: $spacing-small;
    }
    &-p{
      margin-bottom: $spacing-small;
      font-size: rem(20px);
    }
    &-input {
      margin-bottom: $spacing-medium;
      &--field {
        width: 57%;
        margin-top: $spacing-small;
      }
      
    }
  }
}
.project-details-information-footer {
  border-top: 2px solid #e8e8e9;
}