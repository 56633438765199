@import '../settings/settings.scss';

// USER LIST
.user-list {
  &__header {
    min-height: 100px;
    &--text {
      float: left;
      display: flex;
      width: 50%;
      @include md {
        display: inline-block;
        width: 100%;
        padding-bottom: $spacing-smaller;
      }
      h1 {
        padding-bottom: 16px;
      }
    }
    &--button {
      float: right;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin: $spacing-small 0;
      @include md {
        margin-bottom: $spacing-small;
        width: 100%;
        display: inline-flex;
      }
    }
    &--search {
      float: right;
      display: flex;
      justify-content: flex-end;
      width: 50%;
      @include md {
        width: 100%;
        float: right;
        margin-right: 0;
        display: block;
        padding-bottom: $spacing-small;
      }
    }
  }
  &__deleted {
    display: block;
    width: 100%;
  }
  &__custom-link {
    color: $blue-accent;
    cursor: pointer;
  }
  &__alert {
    &--success {
      padding-top: $spacing-smallest;
      color: $green-accent;
    }
    &--warning {
      padding-top: $spacing-smallest;
      color: $red-accent;
    }
  }
  &__labels {
    & .row {
      margin-left: -0.01vw;
    }
    padding: 0 $spacing-medium;
    margin-bottom: -$spacing-small;
    @include md {
      padding: 0 $spacing-smaller 0;
    }
    & .row .col-sm-3,
    & .row .col-sm-5 {
      @include md {
        display: none;
      }
    }
    & .row .col-sm-1 {
      @include md {
        display: block;
      }
    }
  }
}
.awarded-student__header--heading {
  float: none;
}
.body__section.user-list {
  margin: 0.5rem 0;
  @media (max-width: 900px) {
    margin: 0;
  }

  @include md {
    padding: '2rem';
    position: relative;
    border-bottom: $border-thin-grey;
    & .col-sm-1 {
      position: absolute;
    }
    & .col-sm-2 {
      position: absolute;
      margin-left: $spacing-medium;
      max-width: 100%;
      & .user-list__user-name {
        margin-top: $spacing-none;
        max-width: max-content;
      }
      & .row__menus {
        margin-right: $spacing-regular;
        top: 0;
      }
    }
    & .col-sm-3 {
      margin-left: $spacing-medium;
      margin-top: rem(25px);
      & .user-list__user-name {
        margin-top: $spacing-none;
        max-width: max-content;
      }
      @include md {
        margin-top: $spacing-smallest;
        width: 100%;
        max-width: 100%;
        flex: auto;
      }
    }
    & .col-sm-4 {
      @include sm {
        margin-top: $spacing-regular;
      }
      margin-top: $spacing-small;
      margin-left: $spacing-medium;
      max-width: 100%;
      flex: 0 0 100%;
    }
    .chevron-menu {
      display: none;
    }
    & .col-sm-5 {
      @include md {
        margin-left: $spacing-medium;
        padding-bottom: 0;
      }
    }
    & .col-sm-6 {
      @include md {
        margin-left: $spacing-medium;
        padding-bottom: 0;
      }
    }
  }
}

// .col-sm-5 {
//   padding-bottom: $spacing-medium;
// }
