@import '../settings/_fonts';
@import '../settings/_variables';
@import '../settings/_mixins';

// TYPOGRAPHY

h1,
h2,
h3,
h4,
h5,
h6,
p,
link,
blockquote,
a,
html {
  // padding-bottom: $spacing-smallest;
  margin-bottom: $spacing-none;
  line-height: $line-height-short;
  @include md {
    max-width: 100%;
  }
}
body,
html,
div#root {
  height: 100%;
  padding-bottom: 0;
  color: $navigation-grey;
}
h1 {
  font-family: $sans-serif-bold;
  font-size: $size-h1;
  &.h1--strong {
    font-family: $sans-serif-xbold;
    letter-spacing: $letter-spacing;
    line-height: $line-height-short;
  }
  &.h1--thin {
    font-family: $sans-serif;
  }
  &.h1--dotted {
    border-bottom: $border-dotted;
    width: max-content;
    margin: auto;
    &-red {
      border-bottom: $border-dotted-red;
      width: max-content;
      margin: auto;
    }
  }
}
h2 {
  font-family: $sans-serif-bold;
  font-size: $size-h2;
  &.h2--strong {
    font-family: $sans-serif-xbold;
    letter-spacing: $letter-spacing;
    line-height: $line-height-short;
  }
  &.h2--medium {
    font-family: $sans-serif-medium;
    letter-spacing: $letter-spacing;
    line-height: $spacing-medium;
  }
}
#mb_6{
  margin-bottom:-6px;
}

#pb_8{
  padding-bottom:8px;
  }

h3 {
  font-family: $sans-serif;
  font-size: $size-h3;
  &.h3--semi-strong {
    font-family: $sans-serif-bold;
    line-height: $line-height-short;
    &.link {
      color: $blue-accent;
      cursor: pointer;
      &:hover {
        color: #0056b3;
      }
    }
    &.editable {
      // border-bottom: 1px solid #000000;
      padding-bottom: 0;
      margin-right: $spacing-small;
    }
  }
  &.h3--strong {
    font-family: $sans-serif-xbold;
    letter-spacing: $letter-spacing;
    line-height: $line-height-short;
  }
  &.h3--dotted {
    border-bottom: $border-dotted;
    width: max-content;
    // margin: auto;
    &-red {
      border-bottom: $border-dotted-red;
      width: max-content;
      // margin: auto;
    }
  }
  &.h3--ul {
    list-style-type: none;
    padding-left: 0;
  }
}
h4 {
  font-family: $sans-serif;
  font-size: $size-h4;
  letter-spacing: $letter-spacing;
  &.h4--semi-strong {
    font-family: $sans-serif-bold;
    letter-spacing: $letter-spacing;
    width: max-content;
    &.public-link {
      cursor: pointer;
    }
    &.link {
      color: $blue-accent;
      cursor: pointer;
      &:hover {
        color: #0056b3;
      }
    }
    @include md {
      width: auto;
    }
  }
  &.h4--strong {
    font-family: $sans-serif-xbold;
    display: inline-block;
  }
  &.h4--no-margin {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  &.h4--school-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 90%;
  }
}
h5 {
  font-family: $sans-serif;
  font-size: $size-h5;
  letter-spacing: $letter-spacing;
  &.h5--semi-strong {
    font-family: $sans-serif-bold;
    letter-spacing: $letter-spacing;
    &.public-link {
      cursor: pointer;
    }
    &.alert {
      color: $red-accent;
      padding: 0;
    }
    &.mb_20{
      margin-bottom:20px;
    }
    &.mb_18{
      margin-bottom:18px;
    }
    &.blocked {
      color: #878787;
      padding: 0;
    }
    &.success {
      color: $green-accent;
      padding: 0;
    }
    &.link {
      color: $blue-accent;
      cursor: pointer;
      &:hover {
        color: $blue-hover;
      }
    }
  }
  &.h5--strong {
    font-family: $sans-serif-xbold;
    display: inline-block;
  }
}
h6 {
  font-family: $sans-serif;
  font-size: $size-h6;
  letter-spacing: $letter-spacing;
  &.h6--semi-strong {
    font-family: $sans-serif-bold;
    letter-spacing: $letter-spacing;
    padding-bottom: 0;
  }
  &.h6--strong {
    font-family: $sans-serif-xbold;
    letter-spacing: $letter-spacing;
  }
}
p {
  font-family: $sans-serif;
  font-size: $size-body;
  padding-bottom: 0;
  color: $navigation-grey;
  &.body {
    margin-bottom: $spacing-small;
    letter-spacing: $letter-spacing;
  }
  &.reset_p_body{
    margin-bottom: 10px;
    letter-spacing: $letter-spacing;
  }
  &.send_m_body{
    margin-bottom: -4px;
    letter-spacing: $letter-spacing;
  }

  &.p--strong {
    font-family: $sans-serif-bold;
  }
  &.p--half-width {
    max-width: rem(600px);
  }
}
strong {
  font-family: $sans-serif-bold;
}
blockquote {
  font-family: $serif;
  font-size: $size-blockquote;
  &.blockquote--dotted {
    border-bottom: $border-dotted;
    width: max-content;
    // margin: auto;
    &-red {
      border-bottom: $border-dotted-red;
      width: max-content;
      margin: auto;
    }
  }
}
a {
  font-family: $sans-serif-bold;
  color: $blue-accent;
  font-size: $size-body;
  text-decoration: solid;
  &.breadcrumb-link {
    font-size: $size-h5;
  }
  &:hover {
    text-decoration: none;
    color: $blue-hover;
  }
  // & h4.h4--semi-strong:hover {
  //   border-bottom: 4px solid $blue-hover;
  //   width: max-content;
  //   padding-bottom: 0;
  // }
}
.font-size-1rem{
  font-size:1rem
}

// IMAGES
img {
  vertical-align: top;
}
img.photo {
  width: 100%;
}
svg {
  overflow: hidden;
  vertical-align: top;
  // z-index: 2;
}

// CONTAINERS
.body__section {
  margin: $spacing-smaller 0;
  background-color: $white;
  padding: $spacing-medium;
  &--list {
    // LIST STYLING VERT ALIGN
    padding: $spacing-small;
    margin: $spacing-smaller 0;
    background-color: $white;
    & h3 {
      padding-bottom: 0;
    }
    & .chevron-menu {
      margin-top: 0;
    }
  }
}
.container {
  &__main {
    // margin-left: $nav-menu-width;
    // width: calc(100% - #{$nav-menu-width});
    z-index: 1;
    position: relative;
    height: auto;
    min-height: 100%;
    background-color: $background-grey;
    padding-top: rem(64px);
    @include md {
      width: 100%;
      margin-left: 0;
    }
    @include xs {
      padding-top: rem(56px);
    }
    & .white {
      background-color: $white;
    }
  }
  &__public-main {
    margin-left: $nav-menu-width;
    width: 100%;
    margin: auto;
    z-index: 0;
    position: relative;
    @include md {
      width: 100%;
      height: 100%;
      margin-left: 0;
      padding-top: rem(70px);
    }
  }
  &__public-body {
    width: 100%;
    position: relative;
    z-index: 1;
  }
  &__body {
    padding: $spacing-large;
    background-color: $background-grey;
    display: inline-block;
    width: 100%;
    height: auto;
    &.full-width {
      padding: 0;
    }
    &.short-bottom {
      padding: $spacing-large $spacing-large $spacing-medium;
      @include md {
        padding: $spacing-regular $spacing-small;
      }
    }
    &.short-top {
      padding: 0 $spacing-large $spacing-large;
      @include md {
        padding: $spacing-regular $spacing-small;
      }
    }
    &.no-bottom {
      padding: $spacing-large $spacing-large $spacing-none;
      @include md {
        padding: $spacing-regular $spacing-small $spacing-none;
      }
    }
    &--white {
      background-color: $white;
      padding: $spacing-large;
      display: inline-block;
      width: 100%;
    }
    @include md {
      padding: $spacing-large $spacing-regular;
    }
    @include sm {
      padding: $spacing-regular $spacing-small;
    }
  }
  &__project-detail {
    padding: $spacing-large;
    background-color: $background-grey;
    display: inline-block;
    width: 100%;
    &--white {
      background-color: $white;
      padding: $spacing-large;
      display: inline-block;
      width: 100%;
    }
    @include md {
      padding: $spacing-large $spacing-regular;
    }
    @include sm {
      padding: $spacing-regular $spacing-small;
    }
  }
  &__breadcrumbs {
    margin-bottom: $spacing-medium;
    padding: 0 $spacing-large;
    color: $navigation-grey;
    @include md {
      margin-bottom: $spacing-small;
      margin-top: $spacing-smaller;
      padding: $spacing-small 0 0 0;
    }
  }
  &__breadcrum-link {
    font-family: Calibre;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.1px;
    color: #0076BB;
    &--text {
      margin: 3px 0px 3px 6px;
    }
    &--arrow {
      margin: 4px 0px;
    }
  }
  &__body {
    &--photo-detail {
      padding: $spacing-regular $spacing-large;
      display: grid;
      margin: auto;
      background-color: $white;
      @include xxl {
        padding: $spacing-large 20%;
      }
      @include lg {
        padding: $spacing-large 5%;
      }
      @include md {
        width: 100%;
        padding: $spacing-small;
        display: block;
      }
    }
  }
  &__form {
    max-width: rem(500px);
    text-align: center;
    margin: auto auto $spacing-largest auto;
  }
  &__form {
    max-width: rem(500px);
  }
  &__resource-hero {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    &--header {
      width: 60%;
      display: inline-flex;
      align-items: center;
      padding: 0 15% 0 $spacing-large;
      @include xs {
        width: 100%;
        padding: $spacing-large $spacing-regular $spacing-large $spacing-small;
      }
    }
    &--image {
      width: 40%;
      display: inline-flex;
      @include xs {
        width: 100%;
      }
    }
  }
  &__create-class {
    width: 80%;
    margin: auto;
    background-color: $white;
    padding: $spacing-regular;
    @include md {
      width: 100%;
      padding: $spacing-small;
    }
  }
}

// GLOBAL CLASSES
.status-marker {
  display: inline-flex;
  padding-right: $spacing-smallest;
}
.row__menus {
  max-width: max-content;
  float: right;
}

.meatball-menu {
  display: inline-block;
  float: left;
  &:hover {
    cursor: pointer;
  }
}
.chevron-menu {
  display: inline-block;
  float: right;
  margin-top: $spacing-smallest;
  &:hover {
    cursor: pointer;
  }
}
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none;
}
.MuiSwitch-colorPrimary-122.MuiSwitch-checked-121 + .MuiSwitch-bar-125 {
  background-color: $blue-accent !important;
  opacity: 1;
}

// BOOTSTRAP OVERRIDES
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  padding-right: $spacing-none;
  padding-left: $spacing-none;
}
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto {
  @include md {
    width: 100%;
  }
}

.css-c6br4r-control{
  top:-2px;
  height:41px !important;
}

.col-md-true {
  width: 50%;
  @include md {
    width: 100%;
  }
}

.row > * {
  padding-right: 0;
  padding-left: 0;
}

@media (min-width: 576px){ .col-sm-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
}


.row {
  margin-right: 0;
  margin-left: 0;
  --bs-gutter-x: 0;
}
.modal {
  &-content {
    border-radius: 0;
    border: none;
  }
  &-header {
    padding: rem(28px) $spacing-regular $spacing-small $spacing-regular;
    border-bottom: 0px;
  }
  &-body {
    padding: $spacing-small $spacing-regular $spacing-regular;
  }
}
.card {
  border-radius: 0px;
  border: $border-thin-transparent;
  margin-right: $spacing-smaller;
}
.modal-dialog-centered {
  @include md {
    max-width: 90%;
  }
  @include sm {
    max-width: 100%;
  }
}
.Select.is-open {
  position: relative;
  z-index: 1000;
}
.popover-header,
.popover__title {
  border-bottom: 0px;
}
// .modal-header {
//   border-bottom: 0px;
//   padding-bottom: 0px;
// }
.centered {
  text-align: center;
}
.inline {
  display: inline;
}
.create-switch {
  display: flex;
}
.create-switch-label {
  padding-left: 0;
}
.date-to {
  margin-top: 0 !important;
}
.long-school-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
}
.studentUseAgreementStatus {
  margin-top: rem(19px);
}
.projectUserAggreement {
  display: flex;
}
.projectUserAggreement span {
  margin-left: rem(52px);
}

.icon-text-span {
  & svg {
    margin-right: rem(8px);
  }
}

.status {
  &--toast {
    z-index: 3;
    position: fixed;
    margin-top: $spacing-small;
    // min-width: 40%;
    // max-width: 60%;
    border-radius: 16px;
    right: 0;
    @include sm {
      margin-top: 0;
      max-width: 100%;
    }
  }
  &__alert {
    width: max-content;
    color: $red-accent;
  }
  &__success {
    // padding-top: $spacing-smallest;
    color: $green-accent;
  }
  &__warning {
    // padding-top: $spacing-smallest;
    color: $navigation-grey;
  }
  &__grey-warning{
    // padding-top: $spacing-smallest;
    color: $gery53;
  }
}
.edit-text {
  float: right;
  color: #878787;
}

.reset_password{
  &--radio__label{
    font-size: rem(14px);
  }
  &--radio__section{
    margin-top: $spacing-small;
    margin-bottom: $spacing-small;
    &--custom {
      margin-top: $spacing-small;
      margin-bottom: rem(9px);
    }
  }
}
.upcoming__visit {
  &--right-arrow {
    display: block;
    position: absolute;
    background: #00000057;
    color: #fff;
    right: 5px;
    height: 100%;
    padding: 7% 0;
    width: 24px;
    border-radius: 0px 5px 5px 0px;
  }
  &--left-arrow {
    width: 24px;
    display: block;
    position: absolute;
    background: #00000057;
    color: #fff;
    left: 0;
    height: 100%;
    padding: 7% 0;
    border-radius: 5px 0px 0px 5px;
  }

}

.photo-details {
  &-header-timestamp {
    font-size: rem(15px);
    font-family: $sans-serif;
    text-align: right;
    line-height: 1;
    // margin-top: 10px;
  }
}

.pulse-loading {
  position: absolute;
  top: 50%;
  left: 50%;
}

.mt_1p {
  margin-top: 1px;
}

.mt_1r {
  margin-top: 1rem;
}
.mb_18p {
  margin-bottom: rem(18px);
}
#mb_12 {
  margin-bottom: rem(-12px);
}

.MuiPaper-elevation[role="dialog"]::-webkit-scrollbar {
  width: 0;
}

.custom-input-bg .MuiInputBase-root,
.custom-input-bg .MuiInputBase-input:disabled,
.custom-input-bg .MuiSelect-select,
.custom-input-bg .MuiSelect-select:disabled {
  background-color: #FFFFFF !important;
}

.custom-input-bg .MuiInputBase-root::before {
  border-bottom-style: solid !important;
}

.custom-input-bg .MuiInputBase-root:hover{
  background-color: #E7E7E7 !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 118, 187, 1);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*Background white*/
.white {
  background-color: #FFFFFF;
}
