@import '../ui/settings/settings.scss';

.public-events {
  &__hero {
    background-color: $public-yellow;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: top;
    padding: rem(200px) rem(70px) rem(150px);
    -webkit-clip-path: polygon(100% 0, 100% 100%, 36% 100%, 0 92%, 0 0);
    clip-path: polygon(100% 0, 100% 100%, 36% 100%, 0 92%, 0 0);
    @include md {
      display: block;
      padding: $spacing-large 0;
    }
    &--copy {
      width: 50%;
      margin-right: 10%;
      display: inline-block;
      max-width: rem(600px);
      @include md {
        width: 100%;
        display: block;
        padding: $spacing-regular;
      }
      & strong {
        color: $public-red;
      }
    }
    &--header {
      width: 40%;
      height: 100%;
      display: inline-block;
      margin-right: 0;
      & h1.h1--strong {
        color: $public-red;
        font-size: rem(70px);
        line-height: rem(60px);
      }
      @include md {
        padding-left: $spacing-regular;
      }
    }
  }
  &__active-events {
    padding: $spacing-large rem(70px) $spacing-regular;
    @include md {
      padding: $spacing-regular;
    }
    &--header {
      padding: 0 0 $spacing-medium 0;
      & svg {
        margin-top: rem(2px);
      }
    }
    &--back-button {
      padding: $spacing-medium 0;
      & h4.h4--semi-strong.public-link {
        color: $public-red;
      }
    }
    &--event-list {
      display: block;
      align-items: center;
      @include md {
        display: flex;
        flex-direction: column-reverse;
      }
    }
    &--event-details {
      display: inline-flex;
      align-items: top;
      padding-bottom: $spacing-regular;
      @include md {
        display: block;
        width: 100%;
      }
    }
    &--cta {
      & h4.h4--semi-strong.public-link {
        color: $public-red;
      }
    }
  }
  &__past-events {
    padding: $spacing-large rem(70px);
    background-color: $background-grey;
    @include md {
      padding: $spacing-large $spacing-regular;
    }
    &--header {
      padding: 0 0 $spacing-medium 0;
      & svg {
        margin-top: rem(2px);
      }
    }
    &--event-list {
      width: 33.3333%;
      display: inline-block;
      padding-bottom: $spacing-regular;
      @include md {
        width: 100%;
        display: block;
        padding-bottom: $spacing-medium;
      }
    }
  }
  &__details-section {
    display: flex;
    padding-bottom: $spacing-large;
    @include md {
      display: block;
    }
    &--description {
      width: 40%;
      display: inline-block;
      margin-left: 10%;
      @include md {
        width: 100%;
        margin-left: 0;
        padding: $spacing-medium $spacing-regular;
        display: block;
      }
      & h3.h3--strong {
        color: $public-red;
      }
    }
    &--date-location {
      width: 40%;
      padding-left: rem(70px);
      display: inline-block;
      @include md {
        width: 100%;
        padding-left: $spacing-regular;
        display: block;
      }
      & h3.h3--strong {
        color: $public-red;
      }
    }
  }
}

.event-list {
  &__event {
    display: flex;
    width: 100%;
    align-items: center;
    padding-bottom: $spacing-largest;
    @include md {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      padding-bottom: 0;
    }
  }
  &__content-section {
    padding-right: $spacing-large;
    width: 40%;
    display: inline-block;
    @include md {
      display: block;
      width: 100%;
      padding-right: 0;
      float: right;
      margin-bottom: $spacing-regular;
      padding-top: $spacing-small;
    }
    &--header {
      padding: 0 0 $spacing-medium 0;
      & h1.h1--strong {
        color: $public-red;
      }
    }
    & h4.h4--semi-strong.public-link {
      color: $public-red;
    }
  }
  &__image-section {
    width: 50%;
    margin-left: 10%;
    max-height: rem(400px);
    display: flex;
    overflow: hidden;
    align-items: center;
    @include md {
      display: flex;
      overflow: hidden;
      align-items: center;
      width: 100%;
      max-height: rem(500px);
      margin-left: 0;
      padding-bottom: $spacing-small;
    }
    @include sm {
      max-height: rem(250px);
    }
  }
}
.past-event-list {
  &__image-section {
    min-height: rem(200px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @include md {
      max-height: rem(500px);
      min-height: rem(300px);
    }
    @include sm {
      max-height: rem(250px);
    }
  }
  &__content-section {
    &--header {
      padding-top: $spacing-small;
      & a {
        color: $public-red;
      }
    }
    @include md {
      padding-top: $spacing-smaller;
    }
  }
  &__event-container {
    width: 33.333%;
    display: inline-block;
    margin-bottom: $spacing-medium;
    padding-right: $spacing-small;
    @include md {
      width: 100%;
      display: block;
      margin-bottom: $spacing-regular;
    }
  }
}

// EVENT DETAILS PAGES
.event-details {
  &__content-section {
    padding-right: $spacing-large;
    width: 40%;
    display: inline-block;
    @include md {
      width: 100%;
      padding-right: 0;
    }
    &--header {
      padding: 0 0 $spacing-medium 0;
      & h1.h1--strong {
        color: $public-red;
        font-size: rem(60px);
        line-height: rem(60px);
        @include md {
          font-size: $spacing-regular;
          line-height: $spacing-regular;
        }
      }
    }
    & h4.h4--semi-strong.public-link {
      color: $public-red;
    }
  }
  &__image-section {
    width: 50%;
    margin-left: 10%;
    height: 100%;
    display: inline-block;
    @include md {
      width: 100%;
      margin-left: 0;
    }
    & h6 {
      padding-top: $spacing-smaller;
    }
  }
}
