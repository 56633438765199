@import '../settings/settings.scss';

.form-field {
  margin: 0 0 $spacing-smaller 0;
  &.col--left {
    margin-right: $spacing-smaller;
    margin-top: $spacing-small;
    margin-bottom: $spacing-none;
    @include md {
      width: 100%;
      margin-right: 0;
      margin-top: $spacing-small;
    }
  }
  &.col--right {
    margin-top: $spacing-small;
    margin-bottom: $spacing-none;
    @include md {
      width: 100%;
      margin-right: 0;
      margin-top: $spacing-small;
    }
  }
  &.col--mid {
    margin-right: $spacing-smaller;
    margin-top: $spacing-small;
    margin-bottom: $spacing-none;
    @include md {
      width: 100%;
      margin-right: 0;
      margin-top: $spacing-small;
    }
  }
  &.full-size {
    height: rem(120px);
    width: 100%;
    margin-bottom: $spacing-regular;
  }
  &.full-width {
    width: 100%;
    margin-top: $spacing-small;
  }
}

#margin__bottom__10px{
  margin-bottom:10px;
}

.component-error-text {
  width: 100%;
  color: $red-error !important;
  font-size: 1rem !important;
  // margin: 0;
  // margin-top: -1rem !important;
}
// input#outlined-email,
// input#outlined-adornment-current-password {
//   height: rem(16px);
// }
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input#outlined-adornment-current-password,
input#outlined-adornment-new-password,
input#outlined-adornment-repeat-password {
  height: rem(25px);
}
label#outlined-adornment-current-password-label,
label#outlined-adornment-new-password-label,
label#outlined-adornment-repeat-password-label {
  margin-top: 0.3rem;
}

legend{
  float:none !important;
}

