@import '../ui/settings/settings.scss';

.terms-and-policies {
  &__hero {
    height: 100%;
    padding: rem(200px) rem(180px) 0;

    @media screen and (min-width: $tablet) and (max-width: $desktop) {
      padding: rem(200px) 8.25 0;
    }

    @include md {
      display: block;
      padding: $spacing-large 0;
    }
    &--header {
      width: fit-content;
      height: 100%;
      display: inline-block;
      margin-right: 0;
      padding-right: $spacing-regular;
      @include md {
        padding-left: $spacing-regular;
        width: 100%;
      }

      @media screen and (min-width: $tablet) and (max-width: $desktop) {
        padding-left: 3.25rem;
      }

      @media screen and (max-width: $phone) {
        padding-left: $spacing-smaller;
      }

      & h1.h1--strong {
        color: black;
        font-family: 'CalibreTerms';
        font-weight: 400;
        font-style: normal;
        font-size: 60px;
        letter-spacing: 0.02em;
        line-height: 62px;
        text-transform: none;

        @media screen and (min-width: $tablet) and (max-width: $desktop) {
          font-size: rem(60px);
          width: 300px;
          margin-top: 30px;
        }
      }

      & h1.privacy-policy-header {
        margin-bottom: 2rem;
      }
    }
  }
  &__content {
    &__date {
      font-family: Lyon !important;
      font-size: rem(18px) !important;
      line-height: rem(28px) !important;
      letter-spacing: 0.01em !important;
    }
    padding: $spacing-small rem(180px);
    @media screen and (min-width: $tablet) and (max-width: $desktop) {
      padding: $spacing-small 3.25rem;
    }
    @media screen and (max-width: $phone) {
      width: 100vw;
      padding: $spacing-largest $spacing-small;
    }
    display: flex;
    flex-wrap: wrap;
    & h1.h1--semi-strong {
      width: 100%;
      color: $purple-accent;
    }
    &--section {
      padding: rem(20px) 0 0 0;
      display: flex;
      flex-wrap: wrap;
    }
    &--description {
      width: 63%;
      @media screen and (max-width: $phone) {
        width: 95%;
        padding-right: 0;
      }

      @media screen and (min-width: $tablet) and (max-width: $desktop) {
        width: 70%;
      }

      &__title {
        font-size: rem(21px) !important;
        font-family: 'CalibreTerms' !important;
        text-rendering: auto !important;
        -webkit-font-smoothing: antialiased !important;
        font-weight: 500 !important;
        letter-spacing: 0.01em !important;
        line-height: rem(28px) !important;
        text-transform: none !important;
        margin-bottom: 1rem;
      }

      &__list-item--title {
        font-family: 'CalibreTerms' !important;
        text-rendering: auto !important;
        font-weight: 500 !important;
        font-style: normal !important;
        font-size: rem(25px) !important;
        letter-spacing: 0.01em !important;
        line-height: rem(28px) !important;
        text-transform: none !important;
        margin-bottom: 2rem;
      }

      padding-right: $spacing-largest;
      display: inline-block;
      & h3.h2--semi-strong {
        padding-bottom: $spacing-small;
        font-family: Lyon !important;
        font-weight: 900 !important;
        font-size: 21px !important;
        margin-bottom: 5px;

        &.privacy-title {
          font-family: 'CalibreTerms' !important;
          text-rendering: auto !important;
          font-weight: 700 !important;
          font-style: normal !important;
          font-size: rem(25px) !important;
          letter-spacing: 0.01em !important;
          line-height: rem(28px) !important;
          text-transform: none !important;
          padding-bottom: 1rem;
        }

        &.privacy-not-bold {
          line-height: 28px !important;
          letter-spacing: 0.01em !important;
          font-family: Lyon !important;
          font-weight: 300 !important;
          text-transform: none;
          font-style: italic;
          font-size: rem(19px) !important;
        }
      }
      & p.body {
        font-family: Lyon !important;
        font-family: Lyon !important;
        font-size: rem(18px) !important;
        line-height: rem(28px) !important;
        letter-spacing: 0.01em !important;

        & p {
          font-family: Lyon !important;
          font-size: rem(17.5px) !important;
          line-height: rem(28px) !important;
          letter-spacing: 0.005em !important;
          font-weight: 200;

          a {
            color: grey;
            font-family: 'CalibreTerms';
            border-bottom: 1px solid lighten(grey, 20%);
            padding-bottom: 0;
          }
        }

        a {
          color: grey;
          font-family: 'CalibreTerms';
          border-bottom: 1px solid lighten(grey, 20%);
          padding-bottom: 0;
        }

        & h2.h2--semi-strong {
          padding-bottom: $spacing-small;
          font-family: 'CalibreTerms' !important;
          font-weight: 900;
          font-style: normal;
          font-size: 25px;
          letter-spacing: 0.01em;
          line-height: 28px;
          text-transform: none;
        }

        ol {
          list-style-type: decimal;
          font-family: Lyon, serif !important;
          font-size: 17px !important;
          line-height: 35px !important;
          letter-spacing: 0.005em !important;
          font-weight: 200;
          padding-left: 3rem;
          margin-bottom: -0.5rem;
        }
      }

      ol.privacy-ordered-list {
        list-style-type: decimal;
        font-family: Lyon, serif !important;
        font-size: 17px !important;
        line-height: 35px !important;
        letter-spacing: 0.005em !important;
        font-weight: 200;
        padding-left: 3rem;
        margin-bottom: -0.5rem;
      }
    }
    ol {
      list-style-type: none;
      padding-inline-start: 0;

      ul {
        list-style-type: disc;

        li {
          display: flex !important;
          list-style: none;
          display: list-item;
          margin-left: 10px;
          margin-bottom: 10px;

          div {
            margin-left: 10px;
            align-self: flex-start;
          }
        }

        li:before {
          content: '·';
          font-size: 25px;
          vertical-align: middle;
          line-height: 20px;
          margin-top: 5px;
        }
      }
    }
    &--summary {
      padding: rem(45px) 0 0 0;
      width: 30%;
      display: inline-block;
      & h4.h4--semi-strong {
        color: $purple-accent;
        padding-bottom: $spacing-smaller;
      }
      & p.body {
        font-family: $serif;
        line-height: $line-height-base;
      }
    }
  }
}

.app-terms-and-policies {
  &__hero {
    background-color: $background-grey;
    height: 100%;
    display: flex;
    align-items: top;
    padding: rem(200px) rem(70px) rem(150px);
    -webkit-clip-path: polygon(100% 0%, 100% 93%, 33% 100%, 0 93%, 0 0);
    clip-path: polygon(100% 0%, 100% 93%, 33% 100%, 0 93%, 0 0);
    @include md {
      display: block;
      padding: $spacing-large 0;
    }
    &--copy {
      width: 50%;
      margin-right: 10%;
      display: inline-block;
      max-width: rem(600px);
      padding-top: $spacing-regular;
      @include md {
        width: 100%;
        display: block;
        padding: $spacing-regular;
      }
      & h2.h2--medium a {
        color: $public-blue;
        font-size: $size-h2;
        font-family: $sans-serif-medium;
      }
      & h3.h3--semi-strong a {
        color: $public-blue;
        padding-bottom: $spacing-smaller;
      }
    }
    &--header {
      width: 40%;
      height: 100%;
      display: inline-block;
      margin-right: 0;
      @include md {
        padding-left: $spacing-regular;
        width: 100%;
      }
      & h1.h1--strong {
        color: $public-blue;
        font-size: rem(70px);
        line-height: rem(60px);
      }
      & h4.h4--semi-strong a {
        color: $public-blue;
      }
    }
  }
  &__content {
    padding: $spacing-largest rem(75px);
    display: flex;
    flex-wrap: wrap;
    @include md {
      padding: $spacing-large $spacing-regular;
    }
    & h1.h1--semi-strong {
      width: 100%;
      color: $public-blue;
    }
    &--section {
      padding: rem(20px) 0 0 0;
      display: flex;
      flex-wrap: wrap;
    }
    &--description {
      width: 70%;
      padding-right: $spacing-largest;
      display: inline-block;
      & h2.h2--semi-strong {
        padding-bottom: $spacing-small;
      }
      & p.body {
        font-family: $serif;
        line-height: $line-height-base;
      }
      @include sm {
        width: 100%;
        padding-right: 0;
      }
    }
    &--summary {
      padding: rem(45px) 0 0 0;
      width: 30%;
      display: inline-block;
      & h4.h4--semi-strong {
        color: $public-blue;
        padding: 0 0 $spacing-smaller;
      }
      & p.body {
        font-family: Lyon !important;
        line-height: $line-height-base;
      }
      @include sm {
        width: 100%;
        padding: $spacing-smaller 0 0 0;
      }
    }
  }
}

.terms-of-use__subtitle {
  font-size: 1.25rem;
  font-weight: bold;
}
