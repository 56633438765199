@import '../settings/settings.scss';

.modelReleaseList {
  &__header {
    width: 100%;
    display: flex;
    margin-bottom: rem(24px);
    &--text {
      width: 95%;
    }
    &--icon {
      width: 5%;
    }
  }

  &__header-h3 {
    float: left;
  }

  &__header-icon {
    float: right;
    color: #0076BB;
    &:hover {
      color: #0056b3;
      cursor: pointer;
    }
  }
  &__link-section {
    &--anchors{
      font-size: 16px;
      color: #000;
      font-weight: unset;
      font-family: "Calibre", Helvetica, Verdana, sans-serif;
      font-weight: 400;
      color: inherit;
    }
  }
  &__status-text {
    font-size: 16px;
    font-weight: bold;
  }
  &__date-text{
    font-size: 16px;
  }
}
.modelList-links {
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: bolder;
  &span a{
    font-size: 16px;
  }
}

.mr-note {
  &__section {
    margin-top: $spacing-regular;
  }
}