@import '../settings/settings.scss';

.body__shortlist {
  padding: $spacing-large;
  background-color: $white;
  display: inline-block;
  width: 100%;
  @include md {
    padding: $spacing-small;
  }
}
.shortlist {
  &__text {
    width: 60%;
    margin-bottom: $spacing-regular;
    @include sm {
      width: 100%;
    }
  }
  &__empty-container {
    width: 50%;
    padding: $spacing-largest $spacing-small;
    text-align: center;
    display: inline-block;
    border: rem(2px) dashed $light-grey;
  }
  &__photo-container {
    width: 31.3333%;
    margin-right: 2%;
    display: inline-grid;
    margin-bottom: 2%;
    display: inline-grid;
    max-height: rem(300px);
    overflow: hidden;
    background: $black;
  }
  &__accordion{
    &--root {
      border: 0 !important;
      border-radius: 0 !important;
      box-shadow: none !important;
    }
  }
}
