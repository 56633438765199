@import '~sass-rem';
@import '../settings/settings.scss';

// BOOTSTRAP OVERRIDE
.fade.alert-container.alert.alert-dismissible.show {
  background-color: $white;
  color: $dark-grey;
  border: $border-thin-grey;
  margin-bottom: $spacing-regular;
  padding: $spacing-regular $spacing-large $spacing-medium $spacing-largest;
  &.alert-dismissible .close {
    position: absolute;
    top: 0.3125rem;
    padding: 0.75rem 1.25rem;
    color: $dark-grey;
    opacity: 1;
    font-weight: 500;
    display: none;
  }
  @include md {
    padding: $spacing-small $spacing-small $spacing-small rem(70px);
  }
}
.alert {
  margin-bottom: $spacing-regular;
  padding: $spacing-medium;
  &__symbol-container {
    position: absolute;
    width: rem(60px);
    background-color: $red-accent;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 101%;
    justify-content: center;
  }
}
.warning {
  margin-bottom: $spacing-regular;
  padding: $spacing-medium;
  &__symbol-container {
    position: absolute;
    width: rem(60px);
    background-color: $yellow-accent;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 101%;
    justify-content: center;
  }
}
.success {
  margin-bottom: $spacing-regular;
}
.waiting {
  margin-bottom: $spacing-regular;
}
.alert-title {
  color: $red-accent;
  @include md {
    padding-right: $spacing-small;
  }
  & strong {
    color: $red-accent;
  }
}
.warning-title {
  @include md {
    padding-right: $spacing-small;
  }
}
.alert-danger {
  color: $dark-grey;
  background-color: $white;
  border-color: $red-accent;
}

.no-awarded-photo {
  color: $navigation-grey;
}

.message-notifier__photo-overlay {
  svg {
    position: absolute;
    color: white;
    bottom: 1rem;
    right: 1rem;
  }

  &--shortList {
    svg {
      position: absolute;
      color: white;
      bottom: 3rem;
      right: 1rem;
    }
  }
}
