@font-face {
  font-family: 'Calibre Thin';
  src: local('Calibre Thin'), local('Calibre-Thin'),
    url('../../assets/fonts/Calibre-Thin.woff2') format('woff2'),
    url('../../assets/fonts/Calibre-Thin.woff') format('woff'),
    url('../../assets/fonts/Calibre-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: local('Calibre Regular'), local('Calibre-Regular'),
    url('../../assets/fonts/Calibre-Regular.woff2') format('woff2'),
    url('../../assets/fonts/Calibre-Regular.woff') format('woff'),
    url('../../assets/fonts/Calibre-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre Medium';
  src: local('Calibre Medium'), local('Calibre-Medium'),
    url('../../assets/fonts/Calibre-Medium.woff2') format('woff2'),
    url('../../assets/fonts/Calibre-Medium.woff') format('woff'),
    url('../../assets/fonts/Calibre-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre Semibold';
  src: local('Calibre Semibold'), local('Calibre-Semibold'),
    url('../../assets/fonts/Calibre-Semibold.woff2') format('woff2'),
    url('../../assets/fonts/Calibre-Semibold.woff') format('woff'),
    url('../../assets/fonts/Calibre-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre Bold';
  src: local('Calibre Bold'), local('Calibre-Bold'),
    url('../../assets/fonts/Calibre-Bold.woff2') format('woff2'), url('../../assets/fonts/Calibre-Bold.woff') format('woff'),
    url('../../assets/fonts/Calibre-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lyon';
  src: url(https://static1.squarespace.com/static/5d407c48e067be0001711a9a/t/5d533eff7f4edf0001e8c348/1565736703825/Lyon-Regular-Web.woff),
    url(https://static1.squarespace.com/static/5d407c48e067be0001711a9a/t/5d533f2b9bc8840001bdb64e/1565736747560/lyon.ttf),
    url(https://static1.squarespace.com/static/5d407c48e067be0001711a9a/t/5d533efa1b058700010a87a4/1565736698678/Lyon-Regular-Web.otf);
  font-weight: regular;
  font-display: block;
}

@font-face {
  font-family: 'CalibreTerms';
  src: url(https://static1.squarespace.com/static/5d407c48e067be0001711a9a/t/5d533f498fddc10001d0f2ee/1565736777494/CalibreWeb-Regular.woff),
    url(https://static1.squarespace.com/static/5d407c48e067be0001711a9a/t/5d533f520cc4f60001677fb9/1565736786580/Calibre.ttf),
    url(https://static1.squarespace.com/static/5d407c48e067be0001711a9a/t/5d533f458fd2e600011e2359/1565736773891/CalibreWeb-Regular.otf);
  font-weight: 400;
  font-display: block;
}
@font-face {
  font-family: 'CalibreTerms';
  src: url(https://static1.squarespace.com/static/5d407c48e067be0001711a9a/t/5d533f5867f11b000154283e/1565736793078/CalibreWeb-Medium.woff),
    url(https://static1.squarespace.com/static/5d407c48e067be0001711a9a/t/5d533f5c90473f0001639218/1565736796338/Calibre-Medium.ttf),
    url(https://static1.squarespace.com/static/5d407c48e067be0001711a9a/t/5d533f56b8a6f200019d913d/1565736791080/CalibreWeb-Medium.otf);
  font-weight: 500;
  font-display: block;
}
