@import '../settings/settings.scss';


.gallery {
  &__container {
    display: inline-flex;
    flex-wrap: wrap;
    width: 33%;
    @include lg {
      width: 50%;
    }

    @include sm {
      width: 50%;
    }
    @include xs {
      width: 100%;
    }
  }
  &__photo-container {
    width: 100%;
    // margin-right: $spacing-small;
    // margin-bottom: $spacing-small;
    display: inline-block;
    position: relative;
    max-height: rem(260px);
    overflow: hidden;
    background: $black;
    @include xs {
      margin-right: 0;
    }
  }
}

.uploader {

  &__button {
    width: max-content;
    @include sm {
      width: 100%;
    }
    &--active {
      background-color: $transparent-green;
      transition: $transition-fast;
    }
  }
  &__content {
    margin-top: rem(8px);
  }
}


// OVERLAY
.photo-container__hover-content {
  opacity: 0;
  height: 100%;
  position: absolute;
  width: 100%;
  display: flex;
  background: #00000057;
  &:hover {
    opacity: 1;
    transition: $transition-fast;
  }
  &--bottom-left {
    color: $white;
    position: absolute;
    height: max-content;
    display: inline-flex;
    bottom: $spacing-small;
    left: $spacing-small;
    cursor: pointer;
    & a {
      color: $white;
      cursor: pointer;
    }
    & h5.h5--semi-strong {
      cursor: pointer;
    }
  }
  &--bottom-left-above  {
    color: $white;
    position: absolute;
    height: max-content;
    display: inline-flex;
    bottom: rem(42px);
    left: $spacing-small;
    cursor: pointer;
    & a {
      color: $white;
      cursor: pointer;
    }
    & h5.h5--semi-strong {
      cursor: pointer;
    }
  }
  &--top-right {
    position: absolute;
    top: $spacing-small;
    right: $spacing-small;
    &---content {
      color: $white;
      text-align: -webkit-right;
      margin-top: rem(12px)
    }
    &---overlay {
      height: 30px;
      background-color: rgba(0,0,0,0.2);
      padding: 7px;
      border-radius: 33px;
      & svg {
        vertical-align: middle !important;
      }
    }
    & a {
      color: $white;
    }
  }
  &--photo-title {
    color: $white;
    position: relative;
    display: inline-block;
    padding-top: $spacing-smaller;
    left: $spacing-small;
    & a,
    h3.h3--semi-strong {
      color: $white;
      padding-bottom: 0;
    }
  }
  &--clickable-area {
    position: absolute;
    height: 50%;
    width: 95%;
    top: 20%;
    left: 0;
  }
  &--grid-gallery {
    opacity: 0;
    height: 10vw;
    position: absolute;
    width: calc(100%);
    display: flex;
    background: #00000057;
    &:hover {
      opacity: 1;
      padding-right: $spacing-smaller;
      transition: $transition-fast;
    }
  }
  &--download {
    position: relative;
    color: $white;
    height: max-content;
    display: inline-flex;
    padding-right: $spacing-smaller;
    top: rem(13px);
    left: $spacing-small;
    cursor: pointer;
  }

}

.photo-overlay {
  &__trash-icon{
    color: #ffffff;
    cursor: pointer;
  }
}

// MASONRY GALLERY
.photo-grid {
  display: flex;
  margin-left: -$spacing-small;
  width: auto;

  &__column {
    padding-left: $spacing-small;
    background-clip: padding-box;
    // max-width: rem(500px);
  }
}
.masonry__photo-container {
  margin-bottom: $spacing-small;
  position: relative;
  // & .photo-container__hover-content--bottom-left {  STUDENTS SHOULD SEE NOTHING HERE
  //   display: none;
  // }
}
.class-review-masonry-photo-container {
  position: relative;
  // margin-bottom: rem(10px);
}
// .class-review-masonry-photo {
//   margin-bottom: $spacing-small;
// }
.container__photo-gallery--masonry {
  padding: $spacing-large $spacing-largest;
  background-color: $white;
  height: auto;
  @include md {
    padding: $spacing-small $spacing-small;
  }
}
.container__review-photo-gallery--masonry {
  padding: 1rem 0rem 1rem;
  background-color: $white;
  @include md {
    padding: $spacing-small $spacing-small;
  }
}

.photo-container__hover-content--grid-gallery.classReview {
  // height based off of the thumbnail height
  height: 18vw;
  @media (max-width: 48rem) {
    height: 40vw;
  }

  @media (max-width: 75rem) {
    height: 20vw;
  }

  .photo-container__hover-content--bottom-left {
    position: absolute;
    bottom: 7%;
    left: 5%;
  }
}

.photo_letterbox_type {
  height: rem(251px);
  object-fit: contain;
  background-color: #2D2D2D;
}
.student-results__award-heading {
  margin-bottom: $spacing-small;
}