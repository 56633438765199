@import '../settings/settings.scss';

.admin-dashboard {
  &__sections{
  }

  &__table-section {
    display: flex;
    margin-top: $spacing-small;
    margin-bottom: rem(28px);
  }

  &__table-labels {
    border-top: 1px solid #d5d5d7;
    padding: $spacing-small $spacing-small;
  }

  &__table-list {
    border: 1px solid #d5d5d7;
    padding: 1.5rem 1.5rem 2rem 1.5rem;
    border-right: 0;
    border-left: 0;
    overflow: auto;
  }

  &__pagination {
    text-align: center !important;
    font-size: 15px;
    padding: $spacing-small;
  }
  &__hr {
    margin-bottom: rem(44px);
    margin-top: $spacing-medium;
  }
}