@import '../ui/settings/settings.scss';


.photo-details {
  &_page--header {
    padding: 0
  }
}

//Photo Details Page
.container__body--photo-detail .photo {
  max-height: 70vh;
  max-width: 85vw;
  vertical-align: middle;
  cursor: zoom-in;
}

// PHOTO META SECTION
.body__photo-meta {
  max-width: 100%;
  margin: $spacing-regular 0 0 0;
  border-style: solid;
  border-width: rem(1px) 0 0 0;
  border-color: $light-grey;
  text-align: center;
  padding: $spacing-regular 0;
  &--admin {
    border-bottom: 0px;
    margin: 0px;
    padding-bottom: 0;
  }
}
.photo-meta__student-project {
  padding-top: $spacing-smaller;
  & h3.h3--semi-strong {
    padding-bottom: 0;
    text-decoration: underline;
  }
  & h4.h4--semi-strong.link {
    display: inline;
    float: none;
  }
}
.photo-meta__edit {
  margin-top: 30px;
  margin-bottom: 10px;
}

// PHOTO META
.popover {
  border-radius: $radius-none;
  border: $border-thin-grey;
  &__section {
    padding: rem(16px);
  }
  &__title {
    margin-bottom: $spacing-smaller;
    font-family: Calibre;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #2D2D2D;
  }
  &__detail {
    &--label, &--content {
      font-family: Calibre;
      font-style: normal;
      line-height: 22px;
      color: #2D2D2D;
    }
    &--label {

      font-weight: bold;
      font-size: 18px;
    }
    &--content {
      font-weight: normal;
      font-size: 18px;
    }
  }
}

// CURATOR PHOTO VIEW
.photo-detail {
  &__featured-photo {
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
  }
  &__back-button {
    position: relative;
    padding-left: rem(75px);
    color: $blue-accent;
    padding-top: $spacing-small;
    background-color: $white;
    cursor: pointer;
    display: flex;
    width: 100%;
    & h4.h4--semi-strong {
      float: right;
      margin-left: $spacing-smallest;
      margin-top: rem(1px);
    }
    @include md {
      float: none;
    }
  }
  &__more-information {
    display: block;
    width: 100%;
    &--h3{
      margin-bottom: $spacing-regular;
      font-size: 18px;
    }
    & h4.h4--semi-strong.link {
      text-align: left;
      padding-top: 0;
    }
    & .row {
      padding: 0 0 $spacing-regular 0;
      @include sm {
        padding: 0;
      }
    }
    // &--use-agreement,
    // &--files,
    // &--submitted-date,
    // &--published-date,
    // &--awards {
    //   width: 33.333%;
    //   display: inline-block;
    //   @include sm {
    //     width: 100%;
    //     padding: $spacing-smaller 0;
    //   }
    //   & h4.h4--semi-strong {
    //     text-align: left;
    //   }
    //   & h5 {
    //     padding-bottom: $spacing-smaller;
    //   }
    // }
  }
  &__photo-files {
    margin: $spacing-small 0 0;
    &--flex {
      display: flex;
      align-items: center;
    }
    &--replace-photograph {
      display: flex;
      align-items: center;
      margin-bottom: rem(-7px);
      @include sm {
        display: flex;
        flex-wrap: wrap;
      }
      & button.btn.btn-outline-dark.btn-sm {
        margin-right: $spacing-small;
        @include sm {
          margin-bottom: $spacing-smaller;
          margin-right: 0;
        }
        :disabled {
          background-color: transparent;
          cursor: not-allowed;
        }
      }

      & h5 {
        padding-bottom: 0;
      }
    }
    &--add-raw {
      margin-bottom: $spacing-small;
      display: flex;
      align-items: center;
      @include sm {
        display: flex;
        flex-wrap: wrap;
      }
      & button.btn.btn-outline-dark.btn-sm {
        margin-right: $spacing-small;
        @include sm {
          margin-bottom: $spacing-smaller;
          margin-right: 0;
        }
        :disabled {
          background-color: transparent;
          cursor: not-allowed;
        }
      }
      & h5 {
        padding-bottom: 0;
        margin-right: $spacing-small;
      }
    }
  }
  &__file-replace {
    padding: $spacing-large 0;
    border-style: solid;
    border-width: rem(1px) 0 0 0;
    border-color: $light-grey;
  }
  &__award-notification {
    border-style: solid;
    border-width: rem(1px) 0 0 0;
    border-color: $light-grey;
    padding: $spacing-large 0;
  }
}
.featured-photo {
  &__left-arrow {
    margin-left: -$spacing-medium;
    margin-right: $spacing-medium;
    @include md {
      margin-left: 0;
      margin-right: $spacing-smaller;
    }
  }
  &__right-arrow {
    margin-left: $spacing-medium;
    margin-right: -$spacing-medium;
    @include md {
      margin-right: 0;
      margin-left: $spacing-smaller;
    }
  }
}
.award-nominator {
  &__button {
    width: 33%;
    margin-bottom: $spacing-medium;
    &:nth-child(3) {
      padding-right: $spacing-smaller;
      padding-left: $spacing-smaller;
    }
  }
  &__header {
    display: block;
    width: 100%;
    padding-bottom: $spacing-small;
  }
  &__publish-photograph {
    display: block;
    width: 100%;
    padding: rem(48px) 0;
    border-style: solid;
    border-width: rem(1px) 0 0 0;
    border-color: $light-grey;
    &--details {
      max-width: rem(700px);
      margin-top: rem(32px);
    }
  }
  &__collections-section {
    margin-top: $spacing-medium;
    & p {
      margin-bottom: $spacing-small;
    }
    &--options {
      margin-bottom: rem(32px);
    }
  }
}
.award-notification__icon {
  display: inline;
  margin-right: $spacing-smaller;
}
.curator-comments {
  padding: $spacing-large 0;
  border-style: solid;
  border-width: rem(1px) 0 0 0;
  border-color: $light-grey;
  &__description {
    padding-bottom: $spacing-small;
  }
  &__form-field {
    width: 100%;
    display: inline-block;
  }
  &__button {
    display: block;
    margin-top: $spacing-small;
  }
  &__header {
    display: inline-block;
    width: 100%;
    position: relative;
    padding-bottom: $spacing-smaller;
    cursor: pointer;
  }
  & .chevron-menu {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__display{
    margin-top: $spacing-medium;
    &--heading {
      display: flex;
      &---date {
        margin-left: rem(8px) !important;
        color: #878787;;
      }
    }

    &--comment {
      word-break: break-all;
      margin: rem(8px) 0;
    }

    &--delete {
      text-decoration: underline;
      color: $red-accent !important;
      cursor: pointer;
    }
  }
}

// EXTRA OVERRIDES
.button__title h3.h3--semi-strong {
  @include lg {
    font-size: $size-h4;
  }
}
.container__body--photo-detail .body__featured-photo {
  max-width: 75vW;
  margin: auto;
}

.photo-detail__back-button.classDetailProjectBtn {
  display: flex;
  justify-content: space-between;
  padding-right: 75px;
}

.pagination {
  width: 100%;
  display: block;
  text-align: right;



  &__content {
    margin-right: 1.8rem;
  }

  .grey {
    color: #d5d5d7;
  }
}

// for short list photos
.shortlist-photo--contained {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.shortlist-photo--contained__bg {
  background-color: #f7f7f7 !important;
}

.photo-caption {
  line-height: 1.6;
}


.photo-details-footer {
  position: relative;
  min-width: 100vw;
}
