@import '../settings/settings.scss';

.award-processing {
  &__submission-review {
    &--description {
      // width: 45vw;
    }
    &--sub-section {
      margin-top: $spacing-medium;
    }
    &--sub-section ol li {
      margin-left: -20px;
      margin-bottom: 8px;
    }
  }

  &__curatorial-review {
    &--description {
      // width: 45vw;
    }
    &--sub-section {
      margin-top: $spacing-medium;
    }
    &---award-section {
      margin-top: rem(1px);
      // margin-bottom: $spacing-large;
      margin-bottom: 2.625rem;
    }
    &---your-review,
    ---other-curators {
      margin-bottom: $spacing-small;
    }
  }

  &__review-due-date {
    &--section {
      width: 45vw;
      margin-top: -14px;
    }
  }

  &__reopen-review {
    &--section {
      width: 45vw;
      margin-top: rem(28px);
      & h2 {
        padding-bottom: 0;
      }
    }
  }

  &__send-review-section {
    margin-top: rem(7px);
    &--note {
      margin-top: $spacing-small;
    }
    &--button {
      // margin-top: rem(21px);
      margin-top: 1.3755rem;

    }
  }

  &__admin-submit-review {
    &--section {
      width: 45vw;
      margin-top: rem(41px);
      &---button {
        margin-top: rem(25px);
      }
    }
  }

  &__review-summary-section {
    // margin-top: $spacing-large;
    margin-top: 2.562rem;

    &--not-sent {
    }
    &--heading {
      padding-bottom: 0;
    }
    &--instructions {
      // margin-bottom: 2rem;
      margin-bottom: 1.625rem;

      & ol {
        padding-left: rem(18px);
        & li {
          margin-bottom: rem(8px);
        }
      }
      & ul {
        padding-left: rem(16px);
        & li {
          list-style: disc;
        }
      }
    }
    &--button-section {
      margin-bottom: 1.4rem;
      & .button-note {
        margin-top: 0.3rem;
      }
    }
    &--offline-button-section {
      margin-bottom: 2rem;
      & p {
        margin-bottom: 1.625rem;
      }
    }
    &--revert-button-section {
      & h3 {
        margin-bottom: rem(8px);
      }
      &---instructions {
        margin-bottom: $spacing-medium;
      }
    }
  }

  &__more-information-section {
    border-style: solid;
    border-width: rem(1px) 0 0 0;
    border-color: $light-grey;
  }
}

.review_status {
  &--send_curator_section {
  }
  &--send_curator_section ol {
    padding-left: rem(18px);
  }
  &--send_curator_section ul {
    padding-left: 18px;
    list-style-type: disc;
  }
}
.classes__class-details--header {
  margin-bottom: 0;
  h1.h1--strong {
    padding-bottom: 2px;
  }
}

#pb_16
{
  padding-bottom:16px;
}


.photo-detail__more-information--h3 {
  margin-bottom: 1rem;
}

.class-details__labels > .row {
  margin-top: 1rem;
  .col-sm-4 {
    h4 {
      margin-bottom: -6px !important;
    }
  }
}

.second-row {
  margin-top: 19px !important;
}

// .container__body {
//   padding: 3rem 3rem 0 3rem;
// }

.font-1125 {
  font-size: 1.125rem;
}

.student-list__alert--success, .student-list__alert-warning {
  h3 > span.icon-text-span > svg {
    vertical-align: middle;
  }
}

.review-submission-status-text {
  margin-bottom: -4px;
  padding-top: 0 !important;
}

.margin0 {
  margin: 0px !important;
}