@import '../settings/settings.scss';

.use-agreement {
  &__use-agreement-buttons {
    display: block;
    height: auto;
    & .button__title {
      width: auto;
    }
    & .outline-buttons__right, .outline-buttons__left,
      .outline-buttons__right--selected, .outline-buttons__left--selected
     {
      margin-top: 0;
      padding: 3rem 2rem;
    }
    & .outline-buttons__right {
      margin-left: 0;
      @include md {
        width: 100%;
      }
      &--selected {
        margin-left: 0;

        @include md {
          width: 100%;
        }
      }
    }
  }

  &__selection-buttons {
    padding-top: rem(32px);
    display: flex;
  }

  &__buttons{
    width: 100%;
    height: 100%;
    margin: 0;
  }
  &__buttons-section {
    margin-right: rem(15px);
  }
  &__section {
    &--heading {
      margin-bottom: rem(24px);
    }
    &--questions {
      padding-bottom: rem(32px);
    }
    &--questions h3 {
    }
  }
  &__signee-details-section {
    padding-top: rem(48px);
  }

  &__checkbox-section {
    margin-top: rem(24px);
    margin-bottom: $spacing-regular;
  }

  &__instruction-link-section {
    margin-top: $spacing-medium;
  }

  &__instruction-link-section h3.link {
    font-size: rem(18px)
  }
}
