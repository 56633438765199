@import '../settings/settings.scss';

.body__use-agreement {
  padding: 2rem 0;
  border-top: 0.0625rem solid #d5d5d7;
}

#mb_10{
  margin-bottom:10px;
}

.use-agreement {
  &__description{
    margin-top: $spacing-small;
  }
  &__list-history-divider {
    margin: 2rem 0 rem(24px) 0;
  }
  &__p-body {
    margin-top: 1.8rem;
  }
}

.send-pdf__links--downloads {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.model-release__buttons {
  margin-top: rem(24px);
}
