@import '../settings/_variables.scss';
@import '../settings/settings.scss';

body {
  overflow-x: hidden;
}

.class-tracker {
  &__subheading {
    max-height: rem(167px);
    width: 100%;
    background-color: $background-grey;

    &__title {
      font-family: Calibre;
      font-size: 25px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      color: #2d2d2d;
      position: relative;
      left: 2rem;
    }

    &__button-container {
      position: relative;
      @media (min-width: 1300px) and (max-width: 1399px) {
        right: 32px;
        position: absolute;
      }
      @media (max-width: 1299px) {
        right: 49px;
        position: absolute;
        margin-top: 6px;
      }
      right: 32px;
      position: absolute;

      &__send-message-button {
        margin-right: rem(10px);

        .h3--semi-strong {
          margin-left: -5px;
        }
        &:disabled {
          color: #fff;
        }
      }

      &__send-message-button.facilitator-class-tracker {
        position: relative;
      }

      button {
        @media (min-width: 1300px) and (max-width: 1399px) {
          height: 45px;
        }
        @media (max-width: 1299px) {
          height: 40px;
        }
        height: 50px;

        .h3--semi-strong {
          @media (min-width: 1300px) and (max-width: 1399px) {
            font-size: 13px;
          }
          @media (max-width: 1299px) {
            font-size: 11px;
          }
          font-size: 15px;
        }
      }
    }
  }

  &__grid--titles {
    padding: 0 $spacing-small 0;
    margin-bottom: rem(-20px);
    @include md {
      padding: 0 $spacing-smaller $spacing-regular;
    }
    &__header {
      align-items: center;
    }
  }

  &__student-list__row {
    width: 100%;
    background-color: $white;
    border-bottom: 0.0625rem solid #d5d5d7;
    padding: rem(13px) 1rem rem(8px);
    height: auto !important;

    &--final-count {
      width: 173px;
      height: 18px;
      font-family: Calibre;
      font-size: 15px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: 0.1px;
      color: #2d2d2d;
    }

    &--progress {
      display: flex;
      cursor: pointer;
      &--chevron-down {
        position: absolute;
        right: 0rem;
        @media (min-width: 1300px) and (max-width: 1399px) {
          right: -1rem;
        }
        @media (max-width: 1299px) {
          right: -1.5rem;
        }
        top: 0.25rem;
      }
    }

    &__photo-container {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 2rem;
      margin-left: rem(8px);
      h5.classTracker {
        position: relative;
        top: 2rem;
      }

      img {
        object-fit: contain;
      }

      .pagination {
        margin-top: 12px;
        margin-bottom: 1px;
      }
    }

    div[role='tooltip'] {
      z-index: 7;
    }

    div.MuiPaper-root {
      position: relative;
      top: -2.25rem;
    }
  }

  &__no-photos-message {
    display: block;
    text-align: left;
    width: 100%;
    margin-left: 2%;
    margin-top: -4px;
    padding-bottom: 4px;
  }
}

.class-tracker__student-list {
  &__container {
    width: 100%;
    padding-bottom: rem(150px);
    margin-top: $spacing-large;
    &__photograph {
      &__present-btn:enabled
      {
        background-color: #8e5ba6;
        border: #8e5ba6;
        color: #fff;
      }
      &__present-btn:disabled
      {
        color: #fff;
      }
    }
    &__filter-btns {
      background-color: #f7f7f7;
    }
    h3 {
      text-align: justify;
      margin: 0;
      padding: 0;
    }
  }
  &__header {
    display: flex;
    margin-top: -6px;
    &__sub-text {
      margin-top: 13px;
      margin-bottom: 20px;
    }
  }
  &__row--name {
    a {
      text-decoration: underline;
      font-size: 1rem;
    }
  }
  &__project {
    &__not-submiited {
      color: gray;
    }
  }
}

.class-tracker__student-list__row--name---link a {
  font-size: rem(20px);
}

.class-tracker-photo-overlay.photo-container__hover-content--grid-gallery {
  height: rem(251px);
}

.class-tracker-fab {
  position: fixed;
  bottom: rem(20px);
  right: rem(20px);
  z-index: 5;

  button.MuiFab-root {
    background-color: #007ac1 !important;
    width: rem(209px);
    height: (50px);
    color: $white !important;
  }

  &__label {
    margin-left: 0.5rem;
    font-family: Calibre;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: 0.2px;
  }
}
.class-tracker-hero__tabs {
  display: flex;
}

.class-tracker-filters {
  margin-top: rem(14px);
  margin-left: rem(32px);
  font-size: rem(16px);

}

.modal {
  &-title {
    margin-left: -1px;
  }
  &-body {
    padding-bottom: 2rem;
    .invite-student {
      &__description {
        padding-bottom: rem(26px);
        &__custom {
          margin-top: rem(-12px);
          margin-bottom: rem(-6px);
        }
      }
      &__description_margin_bottom_83{
        padding-bottom: rem(26px);
        margin-bottom:-44px;
      }
    }
    .send-message {
      &__button {
        margin-top: rem(29px);
      }
      &__mt_39p_button {
        margin-top: -39px;
      }
    }

    #m_top_32{
      margin-top:32px;
    }

  }
}
.class-tracker__student-list__container__filter-btns {
  margin: 0;
 .class-review__sub-menu {
    background-color: #f7f7f7;
    padding-top: 0;
    margin-top: 0;
  }
  &__container {
    .div_left {
      float: left;
    }
    .div_right {
      float: right;
    }
  }
}

.photo-container__hover-content--grid-gallery {
  width: fit-content;
}

.empty-div {
  &__m-bottom-34 {
    clear: both;
    margin-bottom: rem(34px);
  }
}

.award-result-intro {
  padding-bottom: 9px;
}

.photo-container__hover-content--grid-gallery.classReview {
  width: 28.5%;

}
.message-notifier__photo-overlay--shortList {
  width: fit-content;
  position: relative;
  float: right;
  svg {
    position: inherit;
  }
}

// .photo-container__hover-content--photo-title {
//   top: rem(4px);
// }

.photo-conainer--award {
  position: relative;
  color: $white;
  width: fit-content;
  float: right;
  top: 1rem;
  h4 {
    margin-right: $spacing-small;
    position: absolute;
    top: 0; /* Align at the top of the container */
    right: 0; /* Align at the right side of the container */
    color: rgb(255, 255, 255);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    @media (max-width: 991px){
      right: 32px;
    }
  }
  h4 > svg {
    height: $spacing-small;
    width: $spacing-small;
  }
}
