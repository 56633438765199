@import '../ui/settings/settings.scss';

.login {
  &__header {
    margin: $spacing-smaller 0 0;
    @media screen and (min-width: 600px) {
      margin: $spacing-large 0 0;
    }
    @media screen and (min-width: 900px) {
      margin: $spacing-small 0 0;
    }
    @media screen and (min-width: 1200px) {
      // margin: rem(96px) 0;
    }
  }
  &__disclaimer {
    padding-top: $spacing-regular;
  }
  &__forgot-password {
    padding-top: $spacing-regular;
    & h4.h4--semi-strong.link {
      width: 100%;
    }
  }
  &__subheader {
    margin-top: -$spacing-small;
    padding-bottom: $spacing-small;
  }
}
.container {
  &__login {
    background: #fff;
    max-width: rem(768px);
    margin: auto;
    padding: $spacing-largest;
    text-align: center;
    @include md {
      padding: $spacing-small;
    }
    @media screen and (min-width: 900px) and (max-width: 1200px) {
      max-width: rem(848px);
    }
  }
  &__body--login {
    background-color: $public-light-blue;
    height: auto;
    padding: rem(40px) $spacing-small rem(40px);
    @media screen and (min-width: 600px) {
      padding: rem(80px) $spacing-medium rem(80px);
    }
    @media screen and (min-width: 900px) {
      padding: rem(120px) $spacing-medium rem(120px);
    }

    @media screen and (min-width: 1200px) {
      padding: rem(160px) $spacing-large rem(160px);
    }
  }
}
.wrkxfmly_logo {
  max-width: 200px;
  margin: auto;
}
polygon#X_1_ {
  fill: $purple-accent;
}
.form-field {
  margin: 0 0 $spacing-small 0;
}