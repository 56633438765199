@import '../../ui/settings/settings.scss';

.project-detail {
    &__more-information {
        display: block;
        width: 100%;
        & h4.h4--semi-strong.link {
          text-align: left;
          padding-top: 0;
        }
        & h3 {
            padding-bottom: 0;
        }
        & .row {
            padding-top: 1.5rem;
            @include sm {
                padding: 0;
            }
            & h3 {
                padding-bottom: 0;
                font-family: Calibre;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 22px;
            }
            & h4 {
                padding-bottom: 0.001rem;
                font-family: Calibre;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.02em;
            }
        }
    }
}
.project-details-information-footer > .container__body {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
}