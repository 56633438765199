@import '~sass-rem';
@import '../settings/settings.scss';

.container__toast {
  & h5.h5--semi-strong {
    padding-bottom: 0px;
  }
}
.toast {
  --bs-toast-max-width: 488px;
  & .fade.alert.alert-dismissible.show {
    padding: $spacing-small $spacing-medium; 
    color: $white;
    position: sticky;
    top: 0;
    width: 100%;
    margin-bottom: 0;
    border-radius: $radius-none;
    background-color: $green-accent;
    z-index: 10;
    @include md {
      top: rem(50px);
      padding: $spacing-smaller $spacing-small;
    }
  }
}
.toast-icon {
  display: inline-block;
  padding: 0 $spacing-small 0 0;
}
.toast-message {
  display: inline-block;
  position: absolute;
  top: $spacing-small;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: $spacing-smallest;
  padding: rem(12px) $spacing-small;
  color: $white;
  opacity: 1;
  font-weight: 500;
}
.toast-header {
  align-items: center;
  color: #fff;
  width: 100%;
  padding: rem(4px) 0;
  background-color: transparent;
  background-clip: padding-box;
  border-bottom: none;
  h4.h4--semi-strong {
    width: 98%;
  }
}
.toast.show {
  max-width: 100%;
  overflow: hidden;
  color: $white;
  font-size: 0.875rem;
  background-color: $green-accent; 
  background-clip: padding-box;
  border: none;
  box-shadow: none;
  padding: 9px 2rem 2px;
  margin-bottom: 0px;
  border-radius: 0px;
}
.btn-close {
  color: $white;
  opacity: 1;
  &:hover {
    opacity: 1;
    color: $white;
  }
}
button.btn-close {
  padding: 0;
  position: absolute;
  top: 11px;
  right: 32px;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

// if prop exist, move things around to look nice with a lot of text
.smallText {
  @include md {
    margin-left: -20px;

    padding: 10px 0px;
  }
  @include sm {
    // margin-left: -70px;
    margin-left: -20px;

    padding: 20px 0px;
  }

  button.btn-close {
    @include md {
      right: 20px;
      top: 25px;
    }
    @media (min-width: 1000px) and (max-width: 1111px) {
      right: 55px;
    }
  }
  .toast-message {
    font-size: 0.9em;
    width: 80%;

    @include md {
      top: 30px;
    }
    @media (min-width: 1000px) and (max-width: 1111px) {
      width: 70%;
    }
  }
  .toast-icon svg {
    @include md {
      height: 25px;
      width: 25px;
      margin-right: -10px;
    }
  }
}

.upload-toast.toast---green {
  .toast {
    background-color: #008160 !important;
  }
}

.upload-toast.toast---red {
  .toast {
    background-color: #DE2212 !important;
  }
}

.upload-toast.toast---yellow {
  .toast {
    background-color: #ffcc32 !important;
  }
}

.toast---blue {
  .toast {
    background-color: #005691 !important;
  }
}

.text-span {
  float: right;
  width: 93.5%;
  margin-right: 2px
}

.toast-header > button {
  span::after {
    content: '' !important;
  }
}

.toast-header > button.btn-close {
  padding: 0 !important;
  color: white !important;
  margin-top: 2px;
  height: 1em !important;
  width: 1em !important;
  filter: invert(1) grayscale(100%) brightness(200%);
}

.modal-header > .status--toast {
  position: sticky !important;
  margin-left: 63%;
}

.status--toast {

  z-index: 9999;
}