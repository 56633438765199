@import '../ui/settings/settings.scss';

// BODY SECTIONS
h3.section__title {
  margin-bottom: 5vmin;
}

// FONTS
.section__font {
  &--Libre-Franklin-Regular {
    font-family: $sans-serif;
  }
  &--Merriweather-Light {
    font-family: $serif;
  }
}

// COLORS
.section__color {
  width: 90%;
  height: 8vmin;
  margin-bottom: 1vmin;
  &--black {
    color: $black;
    background: $black;
  }
  &--white {
    color: $white;
    background: $white;
  }
  &--background-accent {
    color: $background-grey;
    background: $background-grey;
  }
  &--light-grey {
    color: $light-grey;
    background: $light-grey;
  }
  &--dark-grey {
    color: $dark-grey;
    background: $dark-grey;
  }
  &--blue-accent {
    color: $blue-accent;
    background: $blue-accent;
  }
  &--red-accent {
    color: $red-accent;
    background: $red-accent;
  }
  &--red-status {
    color: $red-status;
    background: $red-status;
  }
  &--green-status {
    color: $green-status;
    background: $green-status;
  }
  &--green-accent {
    color: $green-accent;
    background: $green-accent;
  }
  &--dark-overlay {
    color: $dark-overlay;
    background-color: $dark-overlay;
  }
  &--transparent-grey {
    color: $transparent-grey;
    background-color: $transparent-grey;
  }
  &--transparent-green {
    color: $transparent-green;
    background-color: $transparent-green;
  }
  &--transparent-red {
    color: $transparent-red;
    background-color: $transparent-red;
  }
}
