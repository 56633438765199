@import '../settings/settings.scss';

// STUDENT LIST
.student-list {
  &__header {
    &--text {
      float: left;
      @include md {
        display: block;
        width: 100%;
        padding-bottom: $spacing-small;
      }
    }
    &--button {
      float: right;
      @include sm {
        width: 100%;
      }
      @include md {
        margin-top: 0;
      }
    }
  }
  &__invite-warning {
    color: $red-accent;
  }
  &__custom-link {
    color: $blue-accent;
    cursor: pointer;
  }
  &__alert {
    &--success {
      padding-bottom: $spacing-smaller;
      color: $green-accent;
      & .icon-text-span {
        color: $green-accent;
      }
    }
    &--warning {
      padding-bottom: $spacing-smaller;
      color: $red-accent;
      & .icon-text-span {
        color: $red-accent;
      }
    }
  }
}
.body__section.student-list {
  @include md {
    padding: $spacing-small $spacing-smaller;
    position: relative;
    margin: 0;
    border-bottom: $border-thin-grey;
    & .col-sm-1 {
      position: absolute;
    }
    & .col-sm-2 {
      position: absolute;
      margin-left: $spacing-medium;
      max-width: 100%;
      & .student-list__student-name {
        margin-top: $spacing-none;
      }
      & .row__menus {
        margin-right: $spacing-regular;
        top: 0;
      }
    }
    & .col-sm-3 {
      margin-left: $spacing-medium;
      margin-top: rem(25px);
    }
    & .col-sm-4 {
      @include sm {
        margin-top: $spacing-regular;
      }
      margin-top: $spacing-small;
      margin-left: $spacing-medium;
      max-width: 100%;
      flex: 0 0 100%;
    }
    .chevron-menu {
      display: none;
    }
  }
}
.thumbnail__photo-container {
  width: 25%;
  height: 100%;
  display: inline-table;
  overflow: hidden;
  position: relative;
  padding-right: $spacing-smaller;
  margin-top: 2%;
  transition: $transition-fast;
  cursor: pointer;
  &--review-details {
    width: calc(33% - 20px);
    height: 100%;
    display: inline-table;
    overflow: hidden;
    position: relative;
    margin-right: $spacing-small;
    margin-top: 2%;
    transition: $transition-fast;
    @include lg {
      width: 50%;
      &:nth-child(2) {
        padding-right: 0;
      }
      &:nth-child(4) {
        padding-right: 0;
      }
    }
    @include sm {
      width: 100%;
      padding-right: 0%;
    }
    & .container__photo {
      background: $black;
      overflow: hidden;
      display: flex;
      width: 100%;
      background-size: cover;
      height: 18vw;
      background-position: center center;
      background-repeat: norepeat;
      margin-bottom: $spacing-smaller;
      @include lg {
        height: 20vw;
      }
      @include sm {
        height: 40vw;
      }
    }
  }
  &--student-list {
    width: calc(25% - 20px);
    height: 100%;
    display: inline-table;
    overflow: hidden;
    position: relative;
    margin-right: $spacing-small;
    margin-top: 2%;
    transition: $transition-fast;
    @include lg {
      width: 50%;
      &:nth-child(2) {
        padding-right: 0;
      }
      &:nth-child(4) {
        padding-right: 0;
      }
    }
    @include sm {
      width: 100%;
      padding-right: 0%;
    }
    & .container__photo {
      background: $black;
      overflow: hidden;
      display: flex;
      width: 100%;
      background-size: cover;
      height: 10vw;
      background-position: center center;
      background-repeat: norepeat;
      margin-bottom: $spacing-smaller;
      @include lg {
        height: 20vw;
      }
      @include sm {
        height: 40vw;
      }
    }
  }
  &:nth-child(4) {
    padding-right: 0;
  }
  @include lg {
    width: 50%;
    &:nth-child(2) {
      padding-right: 0;
    }
    &:nth-child(4) {
      padding-right: 0;
    }
  }
  @include sm {
    width: 100%;
    padding-right: 0%;
  }
  & .container__photo {
    background: $black;
    overflow: hidden;
    display: flex;
    width: 100%;
    background-size: cover;
    height: 12vw;
    background-position: center center;
    background-repeat: norepeat;
    margin-bottom: $spacing-smaller;
    @include lg {
      height: 20vw;
    }
    @include sm {
      height: 40vw;
    }
  }
}
.photo-container__status {
  position: relative;
  display: block;
  width: 100%;
  &--success {
    color: $green-accent;
    position: relative;
    display: block;
    width: 100%;
  }
}
.award__reviewed-by {
  float: right;
  background: $light-grey;
  border-radius: 50%;
  padding: $spacing-smallest;
  margin-right: rem(5px);
  height: 26px;
  width: 26px;
  padding-left: 6px;
  padding-top: 6px;
}
.award-details__review-alert {
  padding: $spacing-small 0 0;
}
.userListRow-col-sm-5 {
  padding-bottom: $spacing-medium;
}
.publish-class__review {
  padding: $spacing-small 0 0;
  margin-bottom: $spacing-small;
  display: inline-flex;
}

.educator-summary{
  margin-right: 10rem;
}

.summarySendDateTime{
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.09px;
  color: #505050;
}

.summarySendText{
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.19px;
  color: #2d2d2d;
}