// Transitions
@mixin transition($time, $type: ease-in-out) {
  transition: all $time $type;
}

// Animations
@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}
@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

// Media queries using defined variables
@mixin xs {
  @media (max-width: #{$phone}) {
    @content;
  }
}
@mixin sm {
  @media (max-width: #{$tablet}) {
    @content;
  }
}
@mixin md {
  @media (max-width: #{$desktop}) {
    @content;
  }
}
@mixin lg {
  @media (max-width: #{$largedesktop}) {
    @content;
  }
}
@mixin xl {
  @media (max-width: #{$fullhd}) {
    @content;
  }
}
@mixin xxl {
  @media (min-width: 1920px) {
    @content;
  }
}
@mixin footer {
  @media (min-width: 1001px) and (max-width: 1200px) {
    @content;
  }
}
