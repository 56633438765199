@import '../ui/settings/settings.scss';

.create-class {
  &__tab-header {
    text-align: center;
    padding: rem(20px) $spacing-regular rem(9px);
    @include md {
      padding: $spacing-smallest;
    }
    &__one {
      &__content {
        padding: rem(19px) 0;
      }
    }
    &__two {
      &__content {
        padding: rem(15px) 0;
      }
      &__submit-btn {
        margin-top: 41px;
      }
    }
    &__three {
      text-align: center;
      padding: 1.25rem 2rem rem(23px);
    }
    &__four {
      &__content {
        margin: rem(15px) 0 -16px;
      }
    }
  }
  &__tab-form {
    text-align: center;
    max-width: 70%;
    margin: auto;
    @include md {
      max-width: 100%;
    }
    p.body {
      padding: rem(21px) 0 rem(13px);
    }

    &__footer-content {
      margin-top: rem(29px);
      margin-bottom: -6px;
    }
    
    &__one {
      &__select {
        margin-top: 32px;
      }
    }
    &__four {
      &__sub-text {
        margin-top: 9px;
        margin-bottom: rem(29px);
      }
    }
  }
  &__cancel {
    text-align: center;
    padding: rem(25px) 0 0 0;
  }
}
.MuiTab-labelContainer-25 {
  @include md {
    display: none;
  }
}
.create-class-datepicker {
  margin: -17px auto;
}

.container__create-class {
  padding-top: rem(14px);
  padding-bottom: rem(8px);
}

.otherGradeDetail {
  margin: rem(31px) 0 rem(49px);
}

h5.date-text {
  text-align: center;
  padding-top: 14px;
}
