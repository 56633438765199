@import '../ui/settings/settings.scss';

// CLASSES LIST & GLOBAL
.classes {
  &__loading {
    z-index: 10;
    position: fixed;
    width: 100vW;
    height: 100vH;
    background: rgba(255,255,255,0.75);
    top: 0;
    left: 0;
  }
  &__cards {
    padding-top: $spacing-small;
    margin-top: rem(12px);
    .card-body {
      display: flex;
      flex: 2;
    }
    .card {
      width: 29%;
      float: left;
      margin-right: rem(42px);
    }
  }
  &__student-list,
  &__updated-time,
  &__next-visit {
    padding-top: $spacing-small;
    width: max-content;
  }
  &__labels {
    & h4 {
      color: $navigation-grey;
    }
    @include md {
      display: none;
    }
  }
  &__header {
    &--actions {
      text-align: right;
      @include sm {
        display: block;
      }
      @include md {
        float: none;
        width: 100%;
      }
    }
    &--button {
      margin: 0 $spacing-small;
      & button.btn.btn-outline-dark.btn-sm {
        padding: rem(12px) rem(16px);
      }
      @include sm {
        float: left;
        margin-left: 0;
        width: 100%;
      }
      @include md {
        display: block;
      }
    }
    &--search {
      float: right;
      margin-right: $spacing-smaller;
      @include sm {
        width: 100%;
        padding: $spacing-smaller 0;
      }
      @include md {
        width: 100%;
        float: right;
        display: block;
        margin-right: 0;
      }
    }
    &--text {
      float: left;
      width: 80%;
    }
    &--sub-text {
      width: 80%;
      margin: 2px 0 rem(28px);
    }
    &__content {
      padding-top: 32px;
    }
  }
  &__days-remaining h5 {
    text-transform: capitalize;
  }
  &__no-classes {
    max-width: rem(700px);
  }
  &__view-photos-link {
    @include md {
      padding-bottom: $spacing-small;
    }
  }
  &__school-name {
    display: inline-block;
    width: 100%;
    padding: 0 0 $spacing-smaller 0;
  }
  &__alert {
    &--approval-warning {
      width: max-content;
      color: $red-accent;
      @include sm {
        margin-bottom: $spacing-small;
      }
    }
    &--curator-review {
      width: max-content;
      position: absolute;
      bottom: 0;
      @include sm {
        margin-bottom: $spacing-small;
      }
    }
    &--approve-class {
      width: max-content;
      position: relative;
      bottom: 0;
      padding-top: $spacing-small;
      color: $red-accent;
      @include sm {
        margin-bottom: $spacing-small;
      }
    }
    &--awards-received {
      width: max-content;
      position: absolute;
      bottom: 0;
      color: $green-accent;
      @include sm {
        margin-bottom: $spacing-small;
      }
    }
  }
  &__list {
    & .col-sm-4 {
      padding-bottom: 0;
      @include md {
        width: 100%;
        max-width: 100%;
      }
    }
    & .body__section {
      position: relative;
      @include md {
        margin: 0;
        border-bottom: $border-thin-grey;
      }
    }
  }
}
.classes__assignment-details--header,
.classes__student-list--header,
.classes__class-details--header,
.classes__header {
  width: 100%;
  display: inline-block;
  // margin-bottom: $spacing-small;
  margin-top: -6px;
}
.classes__header {
  display: flex;
}
.classes__labels,
.student-list__labels {
  padding: $spacing-smallest $spacing-small 0;
  margin-top: -4px;
  color: $grey;
  @include md {
    & .row .col-sm-2,
    & .row .col-sm-3,
    & .row .col-sm-4 {
      display: none;
    }
    & .row .col-sm-1 {
      margin-left: -$spacing-small;
      padding-bottom: $spacing-small;
    }
  }
}
.FormField-root-1 .text-field__search {
  margin: 0;
  padding: 0;
  @include sm {
    padding: 0;
  }
}

// CLASS & ASSIGNMENT DETAILS
.assignment-information--labels {
  margin-top: $spacing-small;
  padding: 0 $spacing-medium;
}
.body__class-details--assignment-information {
  margin-bottom: $spacing-medium;
}


// ASSIGNMENT TIMELINE
.body {
  &__assignment-information {
    padding-bottom: $spacing-large;
    border-bottom: $border-thin-grey;
  }
  &__assignment-timeline {
    padding: $spacing-large 0;
    border-bottom: $border-thin-grey;
  }
  &__project-timeline {
    margin-top: $spacing-small;
  }
}
.assignment-timeline__alert {
  display: inline-block;
  border: rem(2px) solid $red-accent;
  width: 100%;
  background-color: $white;
  padding: $spacing-regular;
  margin-bottom: $spacing-regular;
}
p.assignment-timeline__alert--body {
  max-width: 75%;
  padding-bottom: $spacing-regular;
}
.classes__assignment-schedule {
  &--details {
    max-width: rem(650px);
    padding: $spacing-small 0;
  }
  &--visits {
    padding-top: $spacing-small;
    & .row .col-sm-4,
    & .row .col-sm-3,
    & .row .col-sm-8 {
      display: inline-block;
      @include md {
        width: 100%;
        display: block;
        padding: $spacing-small 0;
      }
    }
    & .row {
      display: block;
    }
  }
  &--checkbox {
    padding-top: $spacing-smaller;
  }
  &--dates {
    margin-top: -$spacing-small;
    & .row .col-sm-4,
    & .row .col-sm-8 {
      display: inline-block;
      @include md {
        width: 100%;
        display: block;
        padding: $spacing-small 0;
      }
    }
    & .row {
      display: block;
    }
  }
  &--section {
    padding: 0 0 $spacing-large 0;
    border-bottom: $border-thin-grey;
    &.timeline {
      padding-top: $spacing-regular;
    }
  }
}
.DatePicker {
  width: rem(300px);
  margin-left: $spacing-none;
  margin-right: $spacing-none;
}
.assignment-schedule__key--container {
  display: inline-flex;
  margin-right: $spacing-medium;
  margin-top: $spacing-smaller;
  @include sm {
    width: 50%;
    display: inline-flex;
    margin-right: 0;
  }
}
.keys__pill {
  width: $spacing-regular;
  height: $spacing-small;
  display: inline-flex;
  border-radius: $spacing-regular;
  margin-right: $spacing-smallest;
  &.class-activity {
    background-color: $darker-grey;
    display: inline-flex;
  }
  &.confirmed-visit {
    background-color: $blue-accent;
    display: inline-flex;
  }
  &.suggested-visit {
    background-color: $light-blue-accent;
    display: inline-flex;
  }
  &.submission-date {
    background-color: $red-accent;
    display: inline-flex;
  }
  &.todays-date {
    background-color: $purple-accent;
    display: inline-flex;
  }
}

// REVIEW DETAILS - Curators
.body__review-details--award-details {
  padding: 0;
  background-color: $white;
  display: inline-block;
  width: 100%;
}
.award-details__row {
  padding: $spacing-regular $spacing-large;
  border-bottom: $border-thin-grey;
}
.review-details {
  &__row--description,
  &__reward-notification {
    max-width: rem(600px);
  }
}

.reivew-list-loader--wrapper {
  position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 9999;
    padding-top: 10px;
    opacity: 0.7;
}

// CLASS PHOTOGRAPHS - Curators
.classes__class-photographs--header {
  padding-bottom: $spacing-medium;
}
.class-photographs__view-badges {
  text-align: right;
  padding-top: 0;
  float: right;
  @include md {
    float: left;
    padding-top: $spacing-smaller;
  }
}
.student-list__header--button {
  @include md {
    float: none;
    width: 100%;
  }
}

h4.h4--semi-strong.schoolName {
  max-width: 300px;
  @media (min-width: 1000px) and (max-width: 1350px) {
    max-width: 200px;
  }
}

.class-detail-fab {
  position: fixed;
  bottom: rem(20px);
  right: rem(20px);
  z-index: 5;

  button.MuiFab-root {
    background-color: #007ac1 !important;
    width: rem(170px);
    height: (50px);
    color: $white !important;
  }

  &__label {
    margin-left: 0.5rem;
    font-family: Calibre;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: 0.2px;
  }
}
// .secondMidPoint {
//   margin-left: rem(10px) !important;
// }

// .secondMidPointLabel {
//   margin-left: 0px !important;
// }

.class-review {
  &__student-list {
    &--intro {
      width: 50vW;
      margin-bottom: $spacing-medium;
    }
    &--submitted-photograph-intro {
      width: 50vW;
    }
  }
}

.card-columns {
  @media (min-width: 576px) {
    column-count: 1;
  }
}

.card-columns .card {
  @media (min-width: 576px) {
    display: inline-block;
    width: 31%;
    float: left;
    margin-right: 2%;
  }
}
.card-body {
  padding: rem(28px) rem(31px) rem(28px) rem(30px);
}

.cards__top-container {
  padding: 0;
}
.cards {
  padding-top: rem(17px);
  margin-bottom: -7px;
}

.classes__header {
  width: 100%;
  display: inline-block;
  margin-top: -4px;
  margin-bottom: -2px;
  &__custom {
    margin-top: rem(35px);
    &__past {
      margin-top: rem(-13px);
    }
  }
}


h4.link {
  text-decoration: underline;
}

.classes__header--text > h1 {
  margin-top: 7px;
}


// To override the styling of dots in carousel package
.react-multi-carousel-dot-list {
  position: relative !important;
  margin-top: rem(40px) !important;
  @include xs {
    margin-top: rem(24px) !important;
  }
  @include sm {
    margin-top: rem(32px) !important;
  }
}
.react-multi-carousel-dot button {
  background: grey !important;
  margin-right: rem(16px) !important
}
.react-multi-carousel-dot--active button {
  background: $blue-accent !important;
  border-color: $blue-accent !important;
  ;
}