@import '../settings/settings.scss';

.class-details{
  &__class-ended-notice {
    border: 1px solid #000;
    background-color: #fff;
    padding: $spacing-small $spacing-medium;
    margin-bottom: $spacing-medium;
    &--icon-text {
      width: 80%;
      display: flex;
    }
    &--icon {
      width: rem(45px);
      // padding-top: $spacing-smaller;
    }
  }

  &--sub-headings{
    font-size: rem(20px);
  }
  &--h4-link {
    text-align: left;
    padding-top: 0;
    color: #0076bb;
    cursor: pointer;
    width: 100%;
  }
  &__labels {
    padding-bottom: 0;
    color: $navigation-grey;
    & .col-md-true {
      padding-bottom: $spacing-smaller;
    }
  }
  &__delete-class {
    margin-top: $spacing-large;
    padding-top: $spacing-large;
    border-top: $border-thin-grey;
    &--description {
      padding: $spacing-smaller 0 0 0;
      max-width: rem(600px);
    }
  }
  &__row {
    margin-bottom: rem(30px);
    margin-top: -2px;
    @include md {
      margin-bottom: 0;
    }
    &-labels {
      margin-top: rem(7px);
    }
  }
  &__add-link {
    padding-top: $spacing-smaller;
  }
  &__additional-details {
    display: block;
    max-width: rem(600px);
    margin-top: 1px;
    margin-bottom: $spacing-large;
  }
  &__admin-notes-box {
    margin-top: rem(29px);
    width: 80%;
  }
  &__approve-class-section{
    display: block;
    max-width: rem(600px);
    margin-top: 4px;
    margin-bottom: $spacing-medium;
  }
  &__approve-class {
    margin-top: rem(25px);
    margin-bottom: rem(-10px);
  }
  &__buttons-section{
    display: flex;
    align-items: center;
    margin-bottom: $spacing-large;
  }
  &__buttons-section .class-details--h4-link {
    padding: $spacing-small;
  }
  &__grade-level-inputs{
    margin-bottom: $spacing-medium;

  }
}

.body__class-details {
  margin-top: -8px;
}

.class__details {
  &--cohort-dates-section {
    width: 60%;
    margin-bottom: rem(35px);
  }
  &--cohort-dates-intro {
    margin-bottom: 7px;
    margin-top: -5px;
  }
  &--cohort-visit-dates-section {
    margin-bottom: $spacing-large;
    &--header {
      margin-bottom: 2px;
    }
    &--intro{
      width: 60%;
    }
    &--dates-section {
      margin-top: 6px;
    }
    &--extra-dates-section {
      margin-top: 18px;
      margin-bottom: 6px;
      width: 60%;
    }
  }
}

.otherGradeDetailAssignment {
  margin: 2rem 0 rem(-19px);
}

.admin-notes {
  margin-top: rem(36px);
}

.class-support {
  margin-top: rem(36px);
}

.class-approval {
  margin-top: -4px;
}

// .editable > .MuiInputBase-fullWidth {
//   width: 94% !important;
// }

.classes__assignment-details--header, .classes__class-details--header > h1.h1--strong {
  padding-bottom: 16px;
}